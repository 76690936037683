.landingWrapper {
  display: flex;
  width: 90vw;
  height: 100%;
  min-height: 100vh;
  max-width: 1620px;
  justify-content: space-between;
  @include forSize(tablet-landscape) {
    width: 100%;
    background-image: url('./../../assets/images/leaf.png');
    background-size: cover;
    background-position: center;
  }
  @include forSize(ipad-pro-portrait) {
    width: 100%;
    background-image: url('./../../assets/images/leaf.png');
    background-size: cover;
    background-position: center;
  }
  .landingLeft {
    display: flex;
    align-items: center;
    flex: 0 1 49%;
    @include forSize(desktop-lg) {
      flex: 0 1 43%;
      margin-left: 40px;
      margin-right: 80px;
    }
    @include forSize(tablet-landscape) {
      display: none;
    }
    @include forSize(ipad-pro-portrait) {
      display: none;
    }
    .imageWrapper {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70%;
      max-height: 750px;
      .image {
        height: 100%;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: 0px 6px 15px $shadowBlack;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .narrowImage {
        flex: 0 1 36%;
        @include fadeDown;
        animation-duration: 1.25s;
      }
      .wideImage {
        flex: 0 1 59%;
        @include fadeUp;
        animation-duration: 1.25s;
      }
      .shape {
        position: absolute;
        background-color: $primary;
        box-shadow: 0px 6px 15px $shadowBlack;
      }
      .circleLeft {
        width: 180px;
        height: 180px;
        left: -70px;
        top: 26%;
        border-radius: 50%;
        z-index: -1;
        @include float;
        animation-delay: -2s;
        @include forSize(desktop-lg) {
          width: 100px;
          height: 100px;
          left: -39px;
          top: 26%;
        }
      }
      .squareBottom {
        width: 65px;
        height: 65px;
        bottom: -38px;
        right: 46%;
        @include float;
        animation-delay: -1s;
        @include forSize(desktop-lg) {
          width: 30px;
          height: 30px;
          bottom: -16px;
        }
      }
      .squareRight {
        width: 180px;
        height: 180px;
        top: 6%;
        right: -48px;
        z-index: -1;
        @include float;
        @include forSize(desktop-lg) {
          width: 100px;
          height: 100px;
          right: -29px;
        }
      }
    }
  }
  .landingRight {
    flex: 0 0 40%;
    @include forSize(ipad-pro-portrait) {
      margin: 0 auto;
      flex: 0 0 100%;
      max-width: 850px;
    }
    @include forSize(tablet-landscape) {
      margin: 0 auto;
      flex: 0 0 90%;
      max-width: 650px;
    }
    @include forSize(phone) {
      flex: 1;
      max-width: 550px;
    }
    .landingBackground {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: var(--cardBg);
      width: 100%;
      min-width: 500px;
      min-height: 90%;
      margin: 0 0 0 auto;
      padding: 0 110px;
      position: relative;
      @include forSize(desktop-lg) {
        padding: 0 80px;
      }
      @include forSize(phone) {
        width: 100%;
        min-width: unset;
        padding: 0 20px;
      }
    }
    .shape {
      display: none;
    }
    @include forSize(tablet-landscape) {
      .shape {
        display: block;
        position: fixed;
        background-color: $primary;
        box-shadow: 0px 6px 15px $shadowBlack;
      }
      .circleLeft {
        width: 80px;
        height: 80px;
        left: -40px;
        top: 20%;
        border-radius: 50%;
        @include float;
        animation-delay: -2s;
      }
      .squareBottom {
        width: 60px;
        height: 60px;
        bottom: -35px;
        right: 46%;
        @include float;
        animation-delay: -1s;
      }
      .squareRight {
        width: 100px;
        height: 100px;
        top: 6%;
        right: -48px;
        @include float;
      }
    }
    @include forSize(ipad-pro-portrait) {
      .shape {
        display: block;
        position: fixed;
        background-color: $primary;
        box-shadow: 0px 6px 15px $shadowBlack;
      }
      .circleLeft {
        width: 200px;
        height: 200px;
        left: -130px;
        top: 20%;
        border-radius: 50%;
        @include float;
        animation-delay: -2s;
      }
      .squareBottom {
        width: 100px;
        height: 100px;
        bottom: -35px;
        right: 46%;
        @include float;
        animation-delay: -1s;
      }
      .squareRight {
        width: 200px;
        height: 200px;
        top: 20%;
        right: -48px;
        @include float;
      }
    }
  }
}

//is authenticated
.dashboardWrapper {
  .mainPanel {
    position: relative;
    float: right;
    width: calc(100vw - 260px);
    // height: 100vh;
    // padding: 5rem 1rem 1rem 1rem;
    overflow-x: initial;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 62px;
    height: calc(100vh - 62px);
    overflow-y: auto;
    @include forSize(desktop-lg) {
      width: calc(100vw - 220px);
    }
    @include media-breakpoint-down(md) {
      width: calc(100vw - 0px);
    }
  }
}
