.will-block {
  background-color: var(--cardBg1);
  border-radius: 10px;
  transition: 0.5s;
  height: 100%;
  border: solid 1px var(--borderGray);
  .Will-head {
    position: relative;
    .image {
      position: relative;
      margin-right: 12px;
      place-self: flex-start;
      span.ant-avatar.ant-avatar-circle.ant-avatar-image {
        width: 50px;
        height: 50px;
        object-fit: cover;
        text-transform: uppercase;
      }
      span.ant-avatar.ant-avatar-circle.ant-avatar-image.joint {
        position: absolute;
        width: 30px !important;
        height: 30px !important;
        font-size: 15px !important;
        bottom: 21px;
        left: 22px;
        box-shadow: 0 0 14px 5px #0000004f;
        border-color: #cdcdcd;
        top: 26px;
        padding: 5px;
      }
      span.ant-avatar.joint.ant-avatar-circle.ant-avatar-icon {
        position: absolute;
        width: 30px !important;
        height: 30px !important;
        font-size: 15px !important;
        bottom: 21px;
        left: 22px;
        box-shadow: 0 0 14px 5px #0000004f;
        border-color: #cdcdcd;
        top: 26px;
        padding: 0px;
        span.anticon.anticon-user svg {
          margin-top: -19px;
        }
      }
      span.ant-avatar.ant-avatar-circle {
        width: 50px;
        height: 50px;
        background-color: $primary;
        border-radius: 50%;
        text-align: center;
        padding: 1px;
        margin-right: 10px;
        color: $white;
        font-size: 25px;
        text-transform: uppercase;
      }
      span.ant-avatar.ant-avatar-circle.joint {
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: $primary;
        border-radius: 50%;
        text-align: center;
        padding: 0px;
        margin-right: 10px;
        color: $white;
        bottom: -10px;
        left: 22px;
        font-size: 14px;
        box-shadow: 0 0 14px 5px #0000004f;
      }
    }
    span.request {
      font-size: 13px;
      color: $success;
    }
    span.rejected {
      font-size: 13px;
      color: $danger;
    }
    span.pending {
      font-size: 13px;
      color: $warning;
    }
    span.active {
      color: var(--title-color);
      position: relative;
      padding-left: 19px;
      border: solid 1px;
      border-radius: 50px;
      padding-right: 10px;
      font-size: 12px;
      font-weight: 400;
      border-color: var(--borderGray);
      background-color: var(--white);
      &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        background-color: #4caf50;
        border-radius: 50%;
        top: 5px;
        left: 6px;
      }
    }
    span.active svg {
      font-size: 18px;
      margin-top: -5px;
    }
    span.active-info span.anticon.anticon-info-circle {
      font-size: 14px;
      margin-left: 4px;
      position: absolute;
      top: 4px;
    }
    span.active-info {
      position: relative;
    }
    .date {
      color: #1559ff;
    }
  }
  .will-info {
    padding: 11px;
    border-radius: 8px;
    margin-bottom: 10px;
    p.mr-1.mb-2 {
      width: 120px;
    }
    // joint-request
    &.jointWill-request {
      background-color: var(--primarySoft);
      border: solid 1px;
      border-color: var(--primarySoft);
      transition: 0.5s;
      h5 {
        color: $primary;
      }
      p {
        margin-top: 8px;
        color: $primary;
        margin-bottom: 0;
      }
    }
    // Accept request
    &.joint-will-accepted {
      background-color: var(--secondarySoft);
      border: solid 1px;
      border-color: var(--secondarySoft);
      transition: 0.5s;
      color: $success;
      h5 {
        color: $success;
      }
      p {
        color: $success;
        margin-top: 8px;
        margin-bottom: 0;
      }
      .btn {
        color: $white;
        background-color: $success;
        border-color: $success;
        &:focus {
          color: $white;
          background-color: $success;
          border-color: $success;
        }
      }
      .tags {
        background-color: $success;
        color: $white;
        border-color: $success;
        svg {
          fill: $white;
        }
      }
    }
    &.delete-joint-will-request {
      background-color: var(--infoSoft);
      border: solid 1px;
      border-color: var(--infoSoft);
      h5 {
        color: $info;
      }
      p {
        color: $info;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
    &.joint-will-request-declined {
      background-color: var(--dangerSoft);
      border: solid 1px;
      border-color: var(--dangerSoft);
      h5 {
        color: $danger;
      }
      p {
        color: $danger;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
    &.delete-joint-will-request {
      background-color: var(--dangerSoft);
      border: solid 1px;
      border-color: var(--dangerSoft);
      h5 {
        color: $danger;
      }
      p {
        color: $danger;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
    &.delete-joint-will-card {
      background-color: var(--dangerSoft);
      border: solid 1px;
      border-color: var(--dangerSoft);
      h5 {
        color: $danger;
      }
      p {
        color: $danger;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
    &.joint-will-activation-request {
      background-color: var(--primarySoft);
      border: solid 1px;
      border-color: var(--primarySoft);
      transition: 0.5s;
      h5 {
        color: $primary;
      }
      p {
        color: $primary;
        margin-top: 8px;
        margin-bottom: 0;
      }
      .btn {
        color: $white;
        background-color: $primary;
        border-color: $primary;
        &:focus {
          color: $white;
          background-color: $primary;
          border-color: $primary;
        }
      }
    }
    &.joint-will-reactivate-request {
      background-color: var(--primarySoft);
      border: solid 1px;
      border-color: var(--primarySoft);
      transition: 0.5s;
      h5 {
        color: $primary;
      }
      p {
        color: $primary;
        margin-top: 8px;
      }
      .btn {
        color: $white;
        background-color: $primary;
        border-color: $primary;
        &:focus {
          color: $white;
          background-color: $primary;
          border-color: $primary;
        }
      }
    }
    &.joint-will-deactivated {
      background-color: var(--infoSoft);
      border: solid 1px;
      border-color: var(--infoSoft);
      h5 {
        color: $info;
      }
      p {
        color: $info;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
  }
  .will-summary-info {
    padding: 11px;
    .list {
      margin-bottom: 10px;
      span {
        background-color: var(--cardBg1);
        font-size: 13px;
        border-color: var(--borderGray);
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 6px;
        &:hover {
          color: $primary;
          background-color: theme-color-level(primary, -10);
          border-color: theme-color-level(primary, -8);
        }
      }
      p {
        font-style: italic;
        color: #42424285;
      }
    }
    svg {
      font-size: 15px;
      margin-top: -4px;
    }
  }
  &:hover {
    box-shadow: 0 0 14px 5px #0000000f;
    background-color: var(--hoverBg);
    transition: 0.5s;
    .joint-will-accepted {
      border: solid 1px;
      border-color: theme-color-level(success, 1);
      transition: 0.5s;
    }
    .jointWill-request {
      border: solid 1px;
      border-color: theme-color-level(primary, 1);
      transition: 0.5s;
    }
    .delete-joint-will-request {
      border: solid 1px;
      border-color: theme-color-level(info, 1);
      transition: 0.5s;
    }
    .joint-will-request-declined {
      border: solid 1px;
      border-color: theme-color-level(danger, 1);
      transition: 0.5s;
    }
    .delete-joint-will-request {
      border: solid 1px;
      border-color: theme-color-level(danger, 1);
      transition: 0.5s;
    }
    .delete-joint-will-card {
      border: solid 1px;
      border-color: theme-color-level(danger, 1);
      transition: 0.5s;
    }
    .joint-will-activation-request {
      border: solid 1px;
      border-color: theme-color-level(primary, 1);
      transition: 0.5s;
    }
    .joint-will-reactivate-request {
      border: solid 1px;
      border-color: theme-color-level(primary, 1);
      transition: 0.5s;
    }
    .joint-will-deactivated {
      border: solid 1px;
      border-color: theme-color-level(info, 1);
      transition: 0.5s;
    }
  }
}

.row {
  $count: 150;
  @for $i from 1 through $count {
    .will-single {
      &:nth-child(#{$i}) {
        .image span.ant-avatar.ant-avatar-circle {
          @include random-bgr();
          text-transform: uppercase;
        }
      }
    }
  }
}
// Change log list
ul{
  $count: 150;
  @for $i from 1 through $count {
    li {
      &:nth-child(#{$i}) {
        .image span.ant-avatar {
          @include random-bgr();
          text-transform: uppercase;
        }
      }
      &:nth-child(#{$i}) {
        .img span.ant-avatar  {
          @include random-bgr();
        }
      }
    }
  }
}

// Will Status list
.will-status-pending {
  .info {
    span.tags {
      border-color: theme-color-level(primary, -8);
      background-color: theme-color-level(primary, -10);
      color: $primary;
    }
    svg {
      font-size: 15px;
      margin-top: 2px;
    }
  }
}

.will-status-request {
  .will-info {
    background-color: theme-color-level(primary, -10);
    color: $primary;
    h5 {
      color: $primary;
    }
    .tags {
      background-color: #f8923433;
      border-color: rgba(244, 67, 54, 0.35);
      svg {
        fill: #f89234;
      }
    }
  }
}
.will-status-accept {
  .will-info {
  }
}
.will-status-changes {
  .will-info {
    background-color: theme-color-level(primary, -10);
    color: $primary;
    h5 {
      color: $primary;
    }
    .btn {
      color: $white;
      background-color: $primary;
      border-color: $primary;
    }
    .tags {
      background-color: #fbc10a;
      color: white;
      border-color: #fbc10a;
      svg {
        fill: white;
      }
    }
  }
}

.Will-preview {
  .will-content {
    background-color: var(--cardBg1);
    width: 100%;
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 28px;
    // border-radius: 7px 7px 0px 0px;
    position: relative;
    // border: solid 1px;
    // border-bottom: none;
    border-color: var(--borderGray);
    h3 {
      font-size: 1.17em;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    h1 {
      font-size: 1.5em;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    h4 {
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    h5 {
      font-size: 0.83em;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    p {
      font-size: 0.875rem;
    }
  }
  .preview-option {
    background-color: #ffffff;
    padding: 9px;
    border-radius: 0px 0px 7px 7px;
    border-top: solid 1px;
    border-color: var(--borderGray);
    .pagination {
      width: 50%;
    }
    .doc-options {
      width: 50%;
      text-align: right;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          margin-left: 16px;
          background-color: #ffe9d5;
          padding: 6px;
          border-radius: 4px;
          color: #ff9800;
        }
      }
    }
  }
}

.will-preview {
  .modal-content {
    background-color: var(--cardBg);
    border: solid 1px var(--borderGray);
    .modal-body {
      padding-top: 0;
      .full-info {
        height: 74vh;
        overflow-y: auto;
        padding-right: 4px;
        h5 {
          margin-bottom: 14px !important;
          svg {
            margin-top: -7px;
          }
        }
      }
      nav.nav.nav-tabs {
        border: none;
        a.nav-item.nav-link {
          border: none;
          background-color: var(--borderGray);
          padding: 3px 10px;
          border-radius: 5px;
          margin: 0px 7px 9px 0px;
          color: var(--text-color);
        }
        .nav-item.nav-link.active {
          background-color: theme-color-level(primary, -10) !important;
          color: $primary;
        }
        a#switch-design-tab-preview {
          margin-left: auto;
        }
      }
    }
    .side-panel-contents {
      position: relative;
      width: 100%;
      right: auto;
      height: 71vh;
      box-shadow: none;
      border: solid 1px;
      padding: 3px;
      border-color: var(--borderGray);
      border-radius: 5px;
    }
  }
  .modal-header {
    border: none;
    .modal-title {
      span.tags {
        margin-top: 4px;
        margin-left: 8px;
      }
    }
    .close {
      width: 40px;
      height: 40px;
      background-color: $white;
      border-radius: 50%;
      margin-top: -2px !important;
      margin-left: 12px !important;
      margin-right: -5px !important;
      padding: 6px;
    }
    .close:not(:disabled):not(.disabled):hover {
      background-color: var(--hoverBg);
    }

    .will-op {
      margin-left: auto;
      display: flex;
      align-items: center;
      button.btn {
        margin-left: 10px !important;
        min-width: auto;
        border: none;
      }
    }
    .modal-title span.tags {
      font-size: 14px;
      font-weight: 400;
    }
    .modal-title span.tags svg {
      margin-top: -5px;
    }
    .modal-title.h4 {
      @include media-breakpoint-down(md) {
        width: 90%;
        display: none;
      }
    }
    span.title-brake {
      display: inline-block;
      max-width: 57%;
      height: 1.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &.executor-view {
    .Will-preview {
      .will-content {
        height: 76vh;
      }
    }
  }
}

ul.attachment-list {
  padding: 0;
  li {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 9px;
    .attachement-file {
      text-align: left;
      background-color: #f7f7f7;
      padding: 12px;
      border-radius: 4px;
      display: flex;
      border: solid 1px;
      border-color: #dfdfdf;
      svg {
        font-size: 18px;
      }
      p {
        margin: 0;
      }
    }
  }
}
.empty-section {
  text-align: center;
  width: 100%;
  height: calc(100vh - 180px);
  @include media-breakpoint-down(xs) {
    padding: 15px;
  }
  img {
    width: 200px;
    margin: 15px;
  }
}
.will-summary-scroll {
  overflow-y: scroll;
  height: 78vh;
  @include media-breakpoint-down(md) {
    height: auto;
  }
}
.change-logs {
  .change-log-list {
    border: solid 1px;
    padding-left: 9px;
    padding-top: 0;
    border-radius: 4px;
    border-color: var(--borderGray);
    margin-top: 10px;
  }
  ul {
    padding: 0;
    padding-top: 13px;
    li {
      margin-bottom: 11px;
      .img {
        margin-right: 9px;
      }
      p {
        margin-bottom: 2px;
        span {
          font-weight: 700;
        }
      }
    }
  }
}
.delete-modal {
  p {
    margin-bottom: 3px;
  }
  .form-group {
    margin-top: 13px;
  }
  .modal_btn_wrp .btn:last-child {
    margin-left: 0;
  }
  span.icon span {
    font-size: 48px;
    margin-bottom: 13px;
    background-color: theme-color-level(primary, -10);
    color: $primary;
    padding: 18px;
    border-radius: 50%;
  }
}
.preview-modal-log ul.list-log .img {
  margin-right: 8px;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}