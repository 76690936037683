.react-datepicker-wrapper {
  width: 100%;
  position: relative;
  input {
    width: 100%;
    display: block;
    border-radius: 3px !important;
  }
  input.form-control.pickadate.picker__input.picker__input--active {
    z-index: 0;
  }
  .input-group-prepend {
    position: absolute;
    height: 96%;
    right: 2px;
    z-index: 0;
    top: 1px;
    .input-group-text {
      border: none;
      border-radius: 0px 5px 5px 0px !important;
      background-color: var(--bodyBg);
      color: var(--text-color)!important;
    }
  }
  label {
    margin-bottom: 3px;
  }
}
.form-control[readonly] {
  background-color: var(--inputsBg)!important;
  opacity: 1;
}
button.react-datepicker__navigation.react-datepicker__navigation--previous {
  outline: none !important;
}
button.react-datepicker__navigation.react-datepicker__navigation--next {
  outline: none !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: white;
  color: #2e2e2e;
}
.react-datepicker__tab-loop {
  z-index: 1;
  display: block;
}
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}
