.Will-preview {
  width: 100%;
  height: auto;
  border: solid 1px;
  border-color: var(--borderGray);
  position: relative;
  box-shadow: 0px 3px 22px -13px rgba(0, 0, 0, 0.27);
  border-radius: 2px;
  .will-content {
    background-color: var(--cardBg1);
    width: 100%;
    height: 81vh;
    overflow-y: auto;
    padding: 28px;
    border-radius: 0px;
    position: relative;
}
  .preview-option {
    min-width: auto !important;
    outline: none !important;
    border: none;
    height: 56px;
    padding-top: 10px;
    background-color: white;
    border-top: solid 1px;
    border-color: var(--borderGray);
    button {
      min-width: auto !important;
      border: none;
      margin-left: 9px !important;
    }
  }
  .pdf-view {
    height: 70vh;
    overflow-y: auto;
    canvas {
      width: 100% !important;
    }
    div {
      width: 100% !important;
      height: auto !important;
      overflow-x: inherit;
      overflow-y: auto;
    }
    .pdf-action-btn {
      position: absolute;
      bottom: 0;
      background-color: #ffffff;
      width: 100% !important;
      padding: 7px;
      border-top: solid 1px;
      border-color: #eeeeee;
      border-radius: 0px 0px 5px 5px;
      transition: 0.5s;
      &:hover {
        background-color: #f7f7f7;
        transition: 0.5s;
      }
      button {
        width: auto !important;
        min-width: fit-content !important;
        margin-right: 8px;
        padding: 6px !important;
        border: none;
      }
      input {
        width: 40px;
        border: solid 1px;
        border-color: #e9e9eb;
        border-radius: 3px;
        text-align: center;
      }
    }
  }
}
.pdf-view-loading {
  width: 100% !important;
  height: 75vh !important;
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
