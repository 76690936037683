.myWillWidget {
  flex: 1 1 100%;
  display: flex;
  .widgetLeft {
    flex: 1 1 60%;
    margin-right: 15px;
  }
  .widgetRight {
    flex: 1 1 40%;
    .checklist {
      display: flex;
      .listLeft {
        margin-right: 30px;
      }
      .listItem {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        img {
          margin-right: 10px;
        }
      }
    }
  }
  .createStatus {
    font-weight: 500;
    margin-top: 5px;
  }
  .createButton {
    max-width: 400px;
  }
}
.myLawyerWidget {
  flex: 1 1 33%;
  display: flex;
  flex-direction: column;
  .selectedLawyerDetails {
    display: flex;
    margin: auto 0;
    .lawyerImage {
      // flex: 0 1 200px;
      img {
        border-radius: 50%;
        width: 130px;
        height: 130px;
        margin-right: 20px;
        object-fit: cover;
        @include forSize(desktop-lg) {
          width: 95px;
          height: 95px;
        }
      }
    }
    .details {
      flex: 1 1 auto;
      .name {
        font-size: 1.2rem;
        font-weight: 600;
      }
      .designation {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .location {
        font-size: 1.1rem;
        font-weight: 500;
      }
    }
  }
  .buttonSection {
    margin-top: auto !important;
  }
}
.myExecutorsWidget {
  flex: 1 1 33%;
  .executor {
    margin-bottom: 10px;
    display: flex;
    .executorImage {
      margin-right: 15px;
    }
    .details {
      .name {
        font-weight: 500;
      }
    }
  }
}
.myBeneficiariesWidget {
  flex: 1 1 33%;
  .benificiary {
    margin-bottom: 10px;
    display: flex;
    .benificiaryImage {
      margin-right: 15px;
    }
    .details {
      .name {
        font-weight: 500;
      }
    }
  }
}
.pendingActionsWidget {
  flex: 1 1 50%;
  .pendingItemsWrapper {
    .item {
      display: flex;
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
      .chevron {
        margin-right: 15px;
      }
      .details {
        .title {
          font-weight: 500;
        }
        .buttonSection {
          margin-top: 10px;
          display: flex;
          align-items: center;
          .actionButton {
            padding: 0.2rem 0.8rem;
            width: 20%;
            max-width: 120px;
          }
          .dismissLink {
            color: #7c7c7c;
            text-decoration: underline;
            font-size: 0.82rem;
            margin-left: 10px;
            font-weight: 300;
          }
        }
      }
    }
  }
}
.revisionHistoryWidget {
  flex: 1 1 50%;
  .revisionHistoryWrapper {
    position: relative;
    .timelineBlock {
      position: relative;
      max-width: 1200px;
      margin: 0 auto;
    }
    &::after {
      content: '';
      position: absolute;
      width: 5px;
      background-color: #dedede;
      top: 15px;
      bottom: 0;
      left: 5px;
    }
    .timelineBlock {
      padding: 10px 35px;
      position: relative;
      background-color: inherit;
    }

    .timelineBlock::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      right: -17px;
      background-color: $primary;
      border: 4px solid $primary;
      top: 15px;
      border-radius: 50%;
      z-index: 1;
    }

    .right::after {
      left: 0px;
    }

    .content {
      background-color: white;
      position: relative;
      border-radius: 6px;
      .title {
        font-weight: 500;
      }
      .date {
        font-size: 0.82rem;
      }
    }

    @media screen and (max-width: 600px) {
      .timeline::after {
        left: 31px;
      }

      .timelineBlock {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
      }

      .timelineBlock::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
      }

      .left::after,
      .right::after {
        left: 15px;
      }

      .right {
        left: 0%;
      }
    }
  }
}

/* Ishara - Dashboard Container */
.dashboard-container .container-fluid {
  padding: 0;

  @media (min-width: 992px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}
