.splashWrapper {
  display: flex;
  @include forSize(tablet-portrait) {
    flex-direction: column-reverse;
    min-height: 100vh;
  }
  .splashLeft {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 150px;
    @include forSize(desktop-lg) {
      padding: 0 140px 0 120px;
    }
    @include forSize(desktop-sm) {
      padding: 0 140px 0 100px;
    }
    @include forSize(tablet-landscape) {
      padding: 0 80px 0 50px;
    }
    @include forSize(tablet-portrait) {
      padding: 0 80px 0 50px;
    }
    @include forSize(phone) {
      padding: 40px;
    }
    .logo {
      max-width: 150px;
      margin-bottom: 150px;
      @include fadeUp;
      @include forSize(desktop-lg) {
        max-width: 230px;
        margin-bottom: 24px;
      }
      @include forSize(tablet-landscape) {
        margin-bottom: 90px;
      }
      @include forSize(tablet-portrait) {
        margin-bottom: 50px;
      }
    }
    .title {
      font-weight: 500;
      line-height: 1.1;
      margin-bottom: 20px;
      @include fadeUp;
      animation-delay: 0.25s;
      .sub {
        font-size: 2.4rem;
      }
      .main {
        font-size: 5.25rem;
        color: $primary;
      }
    }
    .writeUp {
      @include fadeUp;
      animation-delay: 0.25s;
    }
    .buttonSection {
      margin-top: 35px;
      display: flex;
      align-items: center;
      @include fadeUp;
      animation-delay: 0.5s;
      @include forSize(phone) {
        flex-direction: column-reverse;
      }
      .createButton {
        padding: 20px;
        @include forSize(phone) {
          width: 100%;
          margin-top: 10px;
        }
      }
      .skip {
        flex: 0 0 auto;
        margin-left: 30px;
        font-size: 1.01rem;
        color: #7c7c7c;
        text-decoration: underline;
        cursor: pointer;
        @include forSize(phone) {
          width: 100%;
          margin-bottom: 28px;
        }
        .skipIcon {
          margin-left: 5px;
        }
      }
    }
  }
  .splashRight {
    flex: 0 0 31%;
    min-width: 0;
    height: 100vh;
    max-width: 600px;
    @include forSize(desktop-lg) {
      max-width: 400px;
    }
    @include forSize(tablet-landscape) {
      flex: 0 0 26%;
    }
    @include forSize(tablet-portrait) {
      max-width: 100%;
      flex: 0 0 100%;
      max-height: 25vh;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
