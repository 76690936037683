.react-tel-input {
  .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0;
    background-color: var(--inputsBg) !important;
    border: 1px solid var(--borderGray) !important;
    border-radius: 5px;
    line-height: 25px;
    width: 100% !important;
    height: auto !important;
    outline: none;
    padding: 10px 10px 10px 49px;
    font-size: 0.9rem;
    @include forSize(ipad-landscape) {
      padding: 4px;
      padding-left: 48px;
    }
  }
  .flag-dropdown{
    background-color: var(--bodyBg) !important;
    color: var(--text-color)!important;
    border: 1px solid var(--borderGray) !important;
  }
}
.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: var(--hoverBg) !important;
}