// action-inline
.action-list-inline {
  ul {
    padding: 0;
    li {
      display: inline-block;
      .item {
        padding: 6px;
        width: 31px;
        height: 31px;
        display: block;
        margin: 10px;

        border-radius: 100%;

        padding-left: 7px;
      }
    }
    :hover .item {
      background-color: #ff923445;
      color: $primary;
      @include transition();
    }
  }
}

// socical media block
.profile-block {
  text-align: center;
  border: solid 1px;
  padding: 1.1rem;
  border-color:var(--borderGray);
  margin-bottom: 21px;
  min-height: 389px;
  height: 314px;
  border-radius: 20px;
  @include transition();
  @include forSize(phone) {
    min-height: 343px;
  }
  @include forSize(phone-landscape) {
    min-height: 353px;
  }
  @include forSize(ipad-portrait) {
    min-height: 353px;
  }
  @include forSize(ipad-landscape) {
    min-height: 345px;
  }
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
    border: solid 1px;
    padding: 3px;
    border-color: $primary;
    margin-bottom: 19px;
  }
  .log {
    width: 100px;
    height: 100px;
    display: block;
    background-color: $primary;
    text-align: center;
    padding: 27px;
    border-radius: 100%;
    font-size: 34px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    border: solid 1px;
    border-color: $primary;
    margin-bottom: 18px;
  }
  .twitter {
    background-color: #00acee !important;
    border-color: #00acee !important;
  }
  .instagram {
    background: #d6249f;
    background: radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  }
  .linkedin-in {
    background-color: #0e76a8 !important;
    border-color: #0e76a8 !important;
  }
  .facebook {
    background-color: #3b5998 !important;
    border-color: #3b5998 !important;
  }
  .email {
    font-weight: 500;
  }
}
.profile-block:hover {
  box-shadow: 0 0 22px -3px #00000038;
  @include transition();
  border-color: #ffffff;
}

// add block
.add-block {
  display: table;
  height: 100%;
  width: 100%;
  padding: 14px;
  cursor: pointer;
  .contents {
    display: table-cell;
    vertical-align: middle;
    @include transition();

    i {
      font-size: 23px;
      margin: 13px;
    }
  }
}

.add-block:hover {
  color: $primary;
  @include transition();
}

// Separation With Title

.title-separation {
  position: relative;
  span {
    background-color: var(--cardBg);
    z-index: 4;
    position: relative;
    padding: 10px;
    padding-left: 0;
  }
  h6:after {
    content: '';
    position: absolute;
    width: 100%;
    background-color:var(--borderGray);
    height: 1px;
    left: 0;
    bottom: 10px;
  }
}

.table {
  color: #5e676f;
  border-bottom: solid 1px;
  border-color: #dee2e6;
}

// Dropdown-more-icon
.dropdown-more a:after {
  display: none;
}
.dropdown-more .nav-link {
  padding: 0;
}

.table-row button.btn.orange.hollow.w-100 {
  padding: 7px;
}

.cardDefault {
  .titleRow {
    display: flex;
    justify-content: space-between;
    .cardTitle {
      font-size: 1.2rem;
      font-weight: 500;
      // margin-bottom: 15px;
    }
    .link {
      color:#7c7c7c;
      text-decoration: underline;
      font-size: 0.82rem;
    }
  }
  .empty {
    text-align: center;
    width: 100% !important;
    img {
      max-height: 155px;
    }
    .description {
      margin-top: 5px;
    }
    & + .buttonSection {
      margin-top: 15px !important;
    }
    &.twoCol {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .emptyLeft {
        text-align: left;
        line-height: 1;
        .title {
          font-size: 2.3rem;
          font-weight: 600;
          color: $primary;
        }
      }
    }
  }
}

.account-manager {
  .cardTitle {
    margin-bottom: 15px;
  }
  .table-custom {
    margin-top: 30px;
    margin-left: 16px;
    margin-right: 16px;

    @include forSize(phone) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .table-row .btn {
    @include forSize(phone) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.table-custom textarea {
  //     height: 28px !important;
  //     min-height: 33px !important;
  resize: none;
  //      @include forSize(phone) {
  //        height: 58px !important;
}

// }
// .table-custom {
//   margin-top: 30px;
//   margin-left: 16px;
//   margin-right: 16px;
// }

.data-table-pagination {
  padding-top: 22px;
  padding-left: 17px;
  padding-right: 17px;
}
