// Modal doc editor
.doc-editor {
  .modal-body {
    padding: 0;
    .generated-will-editor {
      height: 66vh;
      overflow-y: scroll;
      //   .tox-editor-container {
      //     position: relative !important;
      //     padding-top: 43px;
      //     overflow: hidden !important;
      //     background-color: white;
      //     .tox-editor-header {
      //       position: fixed;
      //       top: 86px;
      //       background-color: white;
      //       width: auto;
      //       z-index: 15;
      //        @include media-breakpoint-down(sm) {
      //        top: 68px;
      //       }
      //       &:after {
      //         background-color: #ffffff;
      //         content: "";
      //         position: absolute;
      //         left: 0;
      //         width: auto;
      //         height: 39px;
      //         top: 0px;
      //         z-index: -1;
      //         border-bottom: solid 1px;
      //         border-color: #cccccc;
      //         @include media-breakpoint-down(xs) {
      //         width: 113%;
      //       }
      //       @include media-breakpoint-down(sm) {
      //             width: 134%;
      //       }
      //       }
      //     }
      //   }
    }
  }
}
.editable-in-editor {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 12px;
  padding: 10px;
  color: #000000;
  border: none !important;
  outline: none;
}

.not-editable-in-editor {
  background-color: #f7f7f7;
  color: #a3a3a3;
  padding-top: 10px;
  padding-bottom: 12px;
  padding: 10px;
  border: none;
  outline: none !important;
}

body#tinymce {
  margin: 0 !important;
}

.user-acceptance-check {
  margin-left: auto;
  margin-top: 7px;
  margin-right: 31px;
}

.user-acceptance-check input {
  margin-right: 7px;
}
