.widgetWrapper {
  .widgetRow {
    justify-content: space-between;
    display: flex;
    .widget {
      margin: 0px 10px 25px;
      @include forSize(desktop-lg) {
        margin: 0px 10px 20px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .buttonSection {
        margin-top: 30px;
      }
    }
  }
}
