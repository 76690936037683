.imageUploader {
  display: block;
  margin: 30px auto 20px;
  text-align: center;
  .controlsWrapper {
    max-width: 250px;
    margin: 0 auto;
    .zoomControl {
      display: flex;
      margin-bottom: 5px;
      input {
        width: 100%;
      }
    }
    .rotateControls {
      justify-content: space-evenly;
      display: flex;
      button {
        border: none;
        background: none;
        border-radius: 5px;
        width: 35px;
        height: 35px;
        &:hover {
          background-color: #dedede;
        }
      }
    }
  }
}
