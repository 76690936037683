.card-widget-wrapper {
  background-color: var(--cardBg);
  @include border-radius(12px);
  padding: 20px;
  margin-bottom: 0.875rem;
  height: calc(100% - 14px);

  .cw-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .cwh-left {
    color: var(--title-color);
    font-size: 1.125rem;
    font-weight: 700;
  }

  .cwh-right {
    color: $info;
    cursor: pointer;
  }

  .ant-avatar {
    line-height: 1.5715 !important;
  }
  .user-list {
    height: 192px;
    overflow: auto;
    @include media-breakpoint-down(md) {
      height: auto;
    }
  }
  .user-list.assets-list {
    height: 274px !important;
    overflow: auto;
    @include media-breakpoint-down(md) {
      height: auto;
    }
  }
  .user-list {
    $count: 150;
    @for $i from 1 through $count {
      .cwb-inner {
        &:nth-child(#{$i}) {
          .ant-avatar {
            @include random-bgr();
          }
        }
      }
      .avatar-row-outer {
        &:nth-child(#{$i}) {
          .ant-avatar {
            @include random-bgr();
          }
        }
      }
    }
  }
  .ant-avatar {
    text-transform: capitalize;
  }

  // .btn {
  //   padding: 13px 40px;
  // }
}
.no-data-wrapper.no-data.widget {
  height: 193px !important;
}