p.view-change-log {
  text-align: right;
  @include media-breakpoint-down(sm) {
    margin-top: 10px;
    margin-bottom: 8px;
  }
}
.side-panel {
  position: fixed;
  width: 300px;
  background-color: #ffffff;
  height: 100vh;
  top: 0;
  right: -303px;
  z-index: 9;
  transition: 0.5s;
  padding: 61px 0px 10px 0px;
  -webkit-box-shadow: -5px 0px 67px -36px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -5px 0px 67px -36px rgba(0, 0, 0, 0.75);
  box-shadow: -5px 0px 67px -36px rgba(0, 0, 0, 0.75);
  .side-panel-header {
    // margin-bottom: 10px;
    border-bottom: solid 1px;
    padding-bottom: 10px;
    border-color: #e7e7e7;
    display: flex;
    padding: 8px 10px 8px 10px;
    align-items: center;
    background-color: whitesmoke;
    .close-icon{
      margin-left: auto;
      padding: 3px;
      background-color: #ffffff;
      position: relative;
      width: 30px;
      height: 30px;
      text-align: center;
      border-radius: 50%;
      &:hover{
        background-color: var(--hoverBg);
        cursor: pointer;
      }
    }
  }
  ul{
    padding: 0;
    li{
      .img{
        margin-right: 10px;
      }
    }
  }
}
.side-panel.active {
  right: 0px;
  transition: 0.5s;
}

ul.list-log {
  padding: 0;
  li {
    margin-bottom: 6px;
    padding-left: 11px;
    padding-right: 4px;
    padding-top: 6px;
    .img {
      h4 {
        width: 45px;
        height: 45px;
        text-align: center;
        background-color: #ff9800;
        color: aliceblue;
        border-radius: 50%;
        padding: 12px;
        margin-right: 11px;
      }
    }
    .info {
      p {
        margin-bottom: 3px;
        span {
          font-weight: 700;
          margin-right: 4px;
        }
      }
      .date {
      }
    }
    &:hover{
      background-color: var(--hoverBg);
    }
  }
}
.list-log{
  $count: 135;
  @for $i from 1 through $count {
    li {
      &:nth-child(#{$i}) {
        .ant-avatar {
          @include random-bgr();
        }
      }
    }
    .avatar-row-outer {
      &:nth-child(#{$i}) {
        .ant-avatar {
          @include random-bgr();
        }
      }
    }
  }
}
.infinite-scroll-component__outerdiv h3 {
  text-align: center;
}
