@mixin fadeIn {
  opacity: 0;
  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation-name: fadeInAnimation;
  animation-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

@mixin fadeUp {
  opacity: 0;
  @keyframes fadeUpAnimation {
    from {
      transform: translate3d(0, 20px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  animation-name: fadeUpAnimation;
  animation-duration: 0.7s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

@mixin fadeDown {
  opacity: 0;
  @keyframes fadeDownAnimation {
    from {
      transform: translate3d(0, -20px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  animation-name: fadeDownAnimation;
  animation-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

@mixin fadeRightChevron {
  opacity: 0;
  @keyframes fadeRightChevronAnimation {
    from {
      transform: translate(-20px, -50%);
    }
    to {
      transform: translate(0, -50%);
      opacity: 1;
    }
  }
  animation-name: fadeRightChevronAnimation;
  animation-duration: 0.25s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

@mixin zoomIn {
  opacity: 0;
  transform: scale(0);
  @keyframes zoomInAnimation {
    from {
      opacity: 0;
      transform: scale(0);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  animation-name: zoomInAnimation;
  animation-duration: 0.5s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-fill-mode: forwards;
}

@mixin float {
  @keyframes floatAnimation {
    0% {
      //   box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
    50% {
      //   box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
      transform: translatey(-20px);
    }
    100% {
      //   box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
      transform: translatey(0px);
    }
  }
  animation-name: floatAnimation;
  animation-duration: 5s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@mixin fadeUpMsg {
  opacity: 0;
  @keyframes fadeUpMsgAnimation {
    from {
      transform: translate3d(0, 20px, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  animation-name: fadeUpMsgAnimation;
  animation-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

@mixin bulge {
  @keyframes bulgeAnimation {
    50% {
      transform: scale(1.02);
    }
  }
  animation-name: bulgeAnimation;
  animation-duration: 5s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-fill-mode: forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

@mixin transition($args: all 0.3s ease 0s) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transition-duration($args1, $args2) {
  -webkit-transition-duration: $args1, $args2;
  -moz-transition-duration: $args1, $args2;
  -o-transition-duration: $args1, $args2;
  transition-duration: $args1, $args2;
}

@mixin transition-delay($args1, $args2) {
  -webkit-transition-delay: $args1, $args2;
  -moz-transition-delay: $args1, $args2;
  -o-transition-delay: $args1, $args2;
  transition-delay: $args1, $args2;
}

@mixin transition-property($args1, $args2) {
  -webkit-transition-property: $args1, $args2;
  -moz-transition-property: $args1, $args2;
  -o-transition-property: $args1, $args2;
  transition-property: $args1, $args2;
}

// background: -moz-linear-gradient(0deg, #91d1ff, #a387ff);
// background: -webkit-linear-gradient(0deg, #91d1ff, #a387ff);
// background: -ms-linear-gradient(0deg, #91d1ff, #a387ff);

@mixin gradient($args1, $args2) {
  -webkit-linear-gradient: (0deg, $args1, $args2);
  -moz-linear-gradient: (0deg, $args1, $args2);
  -o-linear-gradient: (0deg, $args1, $args2);
  -linear-gradient: (0deg, $args1, $args2);
}

@mixin filter($filter-type, $filter-amount) {
  -webkit-filter: $filter-type + unquote('(#{$filter-amount})');
  -moz-filter: $filter-type + unquote('(#{$filter-amount})');
  -ms-filter: $filter-type + unquote('(#{$filter-amount})');
  -o-filter: $filter-type + unquote('(#{$filter-amount})');
  filter: $filter-type + unquote('(#{$filter-amount})');
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

@mixin transform-origin($value) {
  -webkit-transform-origin: $value;
  -moz-transform-origin: $value;
  -ms-transform-origin: $value;
  -o-transform-origin: $value;
  transform-origin: $value;
}

@mixin backface-visibility($value) {
  -webkit-backface-visibility: $value;
  -moz-backface-visibility: $value;
  backface-visibility: $value;
}

@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: -moz-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin animation($args) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -o-animation: $args;
  animation: $args;
}

// Medium Layout: 1280px
@mixin medium {
  @media (min-width: 992px) and (max-width: 1400px) {
    @content;
  }
}

// Tablet Layout: 768px
@mixin tablet {
  @media (min-width: 768px) and (max-width: 1200px) {
    @content;
  }
}

// Mobile Layout: 320px
@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

// Wide Mobile Layout: 480px
@mixin wide-mobile {
  @media (min-width: 480px) and (max-width: 767px) {
    @content;
  }
}

@mixin cmq($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

// Responisve - Media query sizes
@mixin forSize($size) {
  @if $size == galaxy-fold {
    @media (max-width: 280px) {
      @content;
    }
  }
  @if $size == phone-small {
    @media (max-width: 320px) {
      @content;
    }
  }
  @if $size == phone {
    @media (max-width: 598px) {
      @content;
    }
  } @else if $size == phone-landscape-less-than {
    @media (max-width: 767.98px) {
      @content;
    }
  } @else if $size == phone-landscape {
    @media (max-width: 850px) {
      @content;
    }
  } @else if $size == phone-landscape-up {
    @media (min-width: 851px) {
      @content;
    }
  } @else if $size == tablet-portrait {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $size == tablet-landscape-less-than {
    @media (max-width: 991.98px) {
      @content;
    }
  } @else if $size == tablet-landscape {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 769px) {
      @content;
    }
  } @else if $size == tablet-lg {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $size == tablet-lg-up {
    @media (min-width: 1025px) {
      @content;
    }
  } @else if $size == desktop-sm {
    @media (max-width: 1200px) {
      @content;
    }
  } @else if $size == desktop-md {
    @media (max-width: 1366px) {
      @content;
    }
  } @else if $size == desktop-lg {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $size == desktop-lg-up {
    @media (min-width: 1440px) {
      @content;
    }
  } @else if $size == desktop-xl {
    @media (max-width: 1920px) {
      @content;
    }
  } @else if $size == desktop-xl-up {
    @media (min-width: 1920px) {
      @content;
    }
  }
  // device specific media queries
  // ipad
  @else if $size == ipad-portrait {
    @media (min-device-width: 768px) and (max-device-width: 1112px) and (orientation: portrait) {
      @content;
    }
  } @else if $size == ipad-landscape {
    @media (min-device-width: 768px) and (max-device-width: 1112px) and (orientation: landscape) {
      @content;
    }
  }

  //ipad pro
  @else if $size == ipad-pro-portrait {
    @media (min-device-width: 1024px) and (max-device-width: 1112px) and (orientation: portrait) {
      @content;
    }
  } @else if $size == ipad-pro-landscape {
    @media (min-device-width: 1366px) and (max-device-width: 1366px) and (orientation: landscape) {
      @content;
    }
  }
}

/* ---Ishara--- */

/* Border Radius */
@mixin border-radius($property) {
  border-radius: $property;
  -webkit-border-radius: $property;
  -moz-border-radius: $property;
  -ms-border-radius: $property;
  -o-border-radius: $property;
}
@mixin random-bgr(){
  background: rgba(random(120), random(255), (220), (1));
}