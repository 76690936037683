.ant-upload.ant-upload-drag {
  p.ant-upload-text {
    padding-top: 13px;
  }

  p.ant-upload-text span {
    color: $primary;
  }
  span.ant-upload-drag-icon {
    background-color: #80aef869;
    padding: 8px;
    border-radius: 29px;
    display: block;
    width: 60px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    font-size: 24px;
    color: #4086f4;
  }
}
.attached-file {
  padding: 3px 9px;
  background-color:var(--cardBg1);
  border: solid 1px;
  border-color: var(--borderGray);
  margin-top: 8px;
  border-radius: 5px;
  p {
    margin: 0;
    padding: 7px;
    font-weight: 500;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    span.left {
      // float: right;
      font-weight: 500;
    }
  }
  svg {
    font-size: 17px;
    vertical-align: text-top;
  }
  span.closeicon {
    padding: 2px 2px;
    background-color: #ffffff;
    font-size: inherit;
    border-radius: 3px;
    &:hover {
      background-color: #e4e4e4;
    }
  }
}
