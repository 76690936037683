.vidoemaker {
  background-color: aqua;
  width: 100%;
  background-color: #d4d9de;
  margin: 17px;
  margin-left: 0;
  margin-right: 0;
  height: 406px;
}
.vidoemaker img {
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
}
.editor-custom {
  width: 100%;
  border: solid 1px;
  border-color: #dee2e6;
  margin-top: 21px;
  height: 350px;
  margin-bottom: 31px;
}

.rich-editor-custom {
  width: 100%;
  margin-top: 21px;
  height: 393px;
  margin-bottom: 31px;
}

.writeLogForm {
  margin-top: 15px;
}

.email-list {
  display: inline-block;
  // padding: 3px;
  border-radius: 10px;
  // padding-left: 10px;
  // padding-right: 10px;
}
.email-list span {
  background-color: #e6e6e6;
  padding: 2px;
  padding-left: 9px;
  padding-right: 10px;
  border-radius: 18px;
  font-size: 14px;
  white-space: nowrap;
}

.row-style td {
  padding-bottom: 17px;
  padding-top: 17px;
}

.overflow-text {
  width: auto;
  white-space: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.5rem;
}
.text-break {
  word-break: break-all;
}

.title-wrap {
  width: auto;
  white-space: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.2rem;
}
.textarea {
  resize: none;
  overflow: auto;
}

.rich-editor-error {
  color: red;
}
.extra-margin {
  margin-bottom: 10px;
}

textarea#remark {
  height: 80px !important;
  max-height: 100px;
}
.tox-tinymce {
  border: 1px solid #d1d7dc;
  border-radius: 4px;
}
.tox-statusbar {
  display: none !important;
}

.log-grid {
  padding: 20px;
  border: solid 1px;
  border-color: #e6e6e6;
  border-radius: 8px;
  transition: 0.5s;
  // height: 100%;
  &.audio-log {
    background: #fffcf9;
    border: 1px solid #ece1d5;
    box-shadow: 1px 7px 28px -6px #0000000f;
    min-height: 435px;
    position: relative;
    h5 {
      margin-bottom: 5px;
    }
    label {
      margin: 0;
    }
    // p{margin-bottom: 8px;}
    &:hover {
      box-shadow: none;
    }
    ul.emails {
      & ~ p div span a {
        margin-bottom: 20px;
        display: inline-block;
      }
      li {
        padding-left: 0;
        .email-list {
          padding-left: 0;
          span {
            border-radius: 6px;
            background-color: #efe7e0;
            white-space: nowrap;
            &.email_tag {
              border-radius: 8px;
              padding: 8px 15px;
              background-color: #ffeddc;
            }
          }
        }
      }
    }
    .action-list {
      position: absolute;
      right: 18px;
      bottom: 10px;
      ul {
        margin: 0;
        display: inline-block;
        li {
          a.item {
            background-color: #ffecdb;
            border-radius: 10px;
            height: 50px;
            width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              color: #f79234;
            }
            &:hover {
              background: transparent;
              border: 1px solid #f79234;
            }
          }
        }
      }
    }
  }
  ul.emails {
    padding: 0;
    margin: 0 0 18px 0;
    li {
      display: inline-block;
      margin: 0 2px 4px 0;
      border-radius: 8px;
    }
  }
  ul {
    margin-bottom: 0;
  }
  label {
    font-weight: 500;
  }
  i {
    color: #0076ff;
  }
  h5 {
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.4rem;
    vertical-align: middle;
    display: -webkit-inline-box;
  }
  p.remark {
    text-overflow: ellipsis;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.4rem;
    vertical-align: middle;
    display: -webkit-inline-box;
  }
}

.log-grid:hover {
  box-shadow: 1px -3px 26px 4px #0000000f;
  border-color: #ff9222;
  transition: 0.5s;
}

.video-log .action-list ul li a.item {
  padding: 6px;
  background-color: white;
  border-radius: 100%;
  height: 31px;
  width: 31px;
  margin: 1px;
  /* padding-left: 8px; */
  display: block;
  text-align: center;
}

.video-log.video-log-thumbnail {
  p.text-limit1 {
    color: #6e6e6e;
    font-size: 16px;
  }
}
.bold-labels {
  font-weight: bold;
}
.video-recorder__Wrapper-sc-7k20rv-0 {
  height: 622.13px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.render-actions__ActionsWrapper-dp6lnv-0 {
  position: relative !important;
  bottom: 60px !important;
}

//.modal-body {
//    max-height: calc(100vh - 143px);
//    overflow-y: auto;
//}
.video-log .action-list .option-icons {
  position: relative;
}
.video-log .action-list.option-icons span {
  margin-top: 16px;
  position: absolute;
  bottom: 0;
  left: 25px;
}
.remark_description {
  margin-bottom: 30px;
}

.logs-container {
  .tags {
    font-size: 13px;
    padding-left: 0;
    color: #99a0ac;
  }
  .cardTitle {
    margin-bottom: 15px;
  }
}

.video-log-modal {
  .ant-upload-drag-container p {
    padding-top: 0 !important;
  }
}

@media (max-width: 575.98px) {
  .log-grid {
    overflow-x: auto;
    &.audio-log {
      overflow-x: auto;
      min-height: auto;
      .action-list {
        position: relative;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .modal_btn_wrp {
    display: flex;
    .btn {
      min-width: 48%;
    }
  }
  .log-grid.video-log {
    textarea.form-control {
      height: 100px;
      resize: none;
    }
  }
}

// Style Revamp
.create-log-item {
  background-color:var(--lightGray);
  border-radius: 10px;
  padding: 20px 20px 50px 20px;
  margin-bottom: 20px;
  position: relative;
  word-break: break-word;
  overflow-wrap: break-word;
  min-height: 360px;
  span.publish-log {
    background: $primary;
    padding: 3px 10px;
    border-radius: 0 10px;
    color: white;
    font-size: 14px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .text-title {
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 22px;
    font-weight: 700;
    color: var(--title-color);
    margin-top: 10px;
  }
  .c-l-i-email-tag-input,
  .c-l-i-remark-wrp {
    label {
      font-weight: 700;
    }
    .c-l-i-e-t {
      padding: 0;
      display: flex;
      height: 35px;
      li {
        background: white;
        border-radius: 8px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px 10px;
        display: inline-block;
        font-size: 14px;
        box-shadow: 0 4px 15px -6px rgba(0, 0, 0, 0.2);
        &:first-child {
          display: -webkit-inline-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:last-child {
          white-space: nowrap;
        }
      }
    }
  }
  .c-l-i-action {
    display: flex;
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .private-log {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}

@media (max-width: 575.98px) {
  .create-log-item .private-log {
    font-size: 12px;
  }
}
