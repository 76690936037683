.ma-widget {
  .avatar-row-wrapper {
    margin-bottom: 0;
    .av-name {
      font-size: 1.125rem;
      font-weight: 700;
    }
    span {
      display: inline-table;
    }
    .arw-right{
      p{
        color: var(--title-color1);
      }
    }
  }
  .avatar-row-outer {
    margin-bottom: 1rem;

    .avatar-group-wrapper {
      display: inline-flex;
      margin-top: 0.625rem;
      @include media-breakpoint-down(md) {
       margin: 0;
       margin-left: 40px;
      }

      @media (min-width: 992px) {
        margin-top: 0;
      }
    }
  }
}
ul.popover-list {
  padding: 0;
  margin-bottom: 0;
  li {
    margin-bottom: 10px;
    list-style: none;
    .popover {
      border: none;
      -webkit-box-shadow: -1px 4px 21px 0px rgba(0,0,0,0.34);
-moz-box-shadow: -1px 4px 21px 0px rgba(0,0,0,0.34);
box-shadow: -1px 4px 21px 0px rgba(0,0,0,0.34);
      border-radius: 7px;
    }
    .ant-avatar {
      margin-right: 10px;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}
