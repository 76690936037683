// Media Section
.media-log-wrp {
  background:var(--cardBg1);
  border-radius: 10px;
  padding: 20px 20px 50px 20px;
  margin-top: 20px;
  word-break: break-word;
  overflow-wrap: break-word;
  position: relative;
  min-height: 140px;
  h3.m-l-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 400;
    color: var(--title-color);
    margin-bottom: 0;
  }
  p.artist-name {
    margin: 0;
    color: var(--text-color);
  }
}
.media-action {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
