.tagClass {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  cursor: move;
  border-radius: 2px;
}
.react-tags-wrapper {
  .selectedClass {
    text-align: left;
    overflow: auto;
    .tagInputFieldClass {
      padding: 0;
    }
  }
}

.mailtag_count {
  font-size: 12px;
  text-align: left;
  margin-top: 5px;
}

.react-tags-wrapper span.tag-wrapper {
  background: white;
  border-radius: 8px;
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  display: inline-block;
  font-size: 14px;
  box-shadow: 0 4px 15px -6px rgba(0, 0, 0, 0.2);
}

.react-tags-wrapper span.tag-wrapper a {
  margin: 0;
  margin-left: 9px;
}

.selectedClass {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: var(--inputsBg);
  background-clip: padding-box;
  border: 1px solid var(--borderGray);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus-within {
    color: #495057;
    border: 1px solid;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}
.tagInputClass {
  display: inline-block !important;
  .tagInputFieldClass {
    border: none !important;
    &:focus,
    &:hover,
    &:active,
    &:visited {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
