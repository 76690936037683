header {
  .topBar {
    height: 60px;
    width: 100vw;
    background-color: var(--cardBg);
    box-shadow: 0 0 14px 5px $shadowBlack;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    .menu-side button {
      background: none;
      color: var(--title-color);
      height: 45px;
      width: 45px;
      padding: 0 !important;
      border-radius: 50%;
      font-size: 18px;
      vertical-align: text-bottom;
      border: none;
      svg {
        margin-top: -4px;
      }
    }
    nav.navbar {
      .logo {
        padding: 7px;
        padding-left: 0;
        margin-right: 0;
        img {
          width: 194px;
          height: 47px;
          margin-top: -6px;

          @include media-breakpoint-only(xs) {
            width: 106px;
          }
        }
      }
    }
    .user-menu {
      ul {
        li {
          display: inline-block;
          .dropdown {
            .ant-avatar {
              background-color: $primary;
              color: $white;
            }
            button {
              background: none;
              border-radius: 50%;
              width: 40px;
              height: 40px;
              padding: 0;
              // background-color: #99a0ac61;
              color: #2d2d2d;
              border: none;
              font-size: 1.3rem;
              box-shadow: none;
              &:after {
                display: none;
              }
              &:hover {
                background-color: var(--hoverBg);
              }
              &:active {
                color: #ff9234;
                background-color: theme-color-level(primary, -10);
                border-color: #1c743000;
                h5 {
                  color: #ff9234;
                }
                span {
                  color: var(--primary);
                }
              }
              &:focus {
                color: #ff9234;
                background-color: theme-color-level(primary, -10);
                border-color: #1c743000;
                box-shadow: none !important;
                h5 {
                  color: #ff9234;
                }
                span {
                  color: var(--primary);
                }
                span.ant-avatar {
                  background-color: theme-color-level(primary, -10);
                  color: $primary;
                }
              }
              span {
                color: var(--title-color);
              }
              .new-count {
                position: absolute;
                top: 6px;
                right: 5px;
                background-color: $danger;
                color: $white;
                font-size: 12px;
                z-index: 20;
                border-radius: 50%;
                width: 17px;
                height: 17px;
                &.more {
                  width: 22px;
                  border-radius: 50px;
                  top: 3px;
                  left: 15px;
                }
              }
            }
          }
          .account.dropdown {
            button {
              h5.name {
                font-size: 1.3rem;
                margin-top: 3px;
              }
            }
            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              border-radius: 50%;
            }
            .dropdown-menu {
              width: 300px;
              padding: 9px;
              top: 2px !important;
              border-color: ver(--borderGray);
              box-shadow: 0px 7px 34px #00000021;
              background-color: var(--cardBg1);
              border-radius: 7px;
              border: none;
              a.dropdown-item {
                font-size: 1rem;
                padding: 11px;
                border-radius: 8px;
                color: var(--text-color);
                &:hover {
                  background-color: var(--hoverBg);
                }
                span {
                  font-size: 1.6rem;
                  margin-right: 5px;
                }
              }
              .profile {
                padding: 7px;
                border-radius: 9px;
                &:hover {
                  background-color: var(--hoverBg);
                }
                h4 {
                  margin: 0;
                  text-transform: capitalize;
                  margin-top: 10px;
                }
                img {
                  width: 45px !important;
                  height: 45px !important;
                  object-fit: cover;
                  border-radius: 50%;
                }
                span.ant-avatar {
                  background: $primary !important;
                }
                h5.name {
                  width: 45px;
                  height: 45px;
                  object-fit: cover;
                  border-radius: 50%;
                  text-align: center;
                  padding: 12px;
                  background-color: #ff9800;
                  color: $white;
                  text-transform: capitalize;
                  font-size: 1.3rem;
                }
                p {
                  margin: 0;
                }
              }
              .info p {
                margin: 0;
              }
            }
          }
          a.profile.nav-link img {
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
          a.profile.nav-link {
            vertical-align: middle;
            display: flex;
            align-items: center;
            border-radius: 50px;
            padding: 0;
            padding-left: 17px;
            padding-right: 1px;
            h5 {
              margin: 0;
              margin-right: 3px;
            }
          }
        }
      }
    }
    .notification {
      .dropdown-menu {
        box-shadow: 0px 7px 34px #00000021;
        background-color: var(--cardBg1);
        color: var(--title-color);
        border-radius: 7px;
        border: none;
        min-height: 31rem;
        height: 400px;
        &:hover {
          &::-webkit-scrollbar-thumb {
            background-color: #d8d8d8;
          }
          &::-webkit-scrollbar-track {
            background-color: #e4e4e4;
          }
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ffffff;
          border-radius: 0px 10px 10px 0px;
        }
        &::-webkit-scrollbar-track {
          background: #ffffff;
          border-radius: 0px 10px 10px 0px;
        }
        .dropdown-item {
          .notifications-info {
            width: 300px;
            @include media-breakpoint-down(sm) {
              width: 244px;
            }
          }
          button {
            width: 100% !important;
            border-radius: inherit !important;
          }
          &:hover{
            background-color: var(--hoverBg);
          }
        }
        .dropdown-divider {
          border-top: 1px solid var(--borderGray);
          margin-bottom: 0;
        }
      }
      .notification-list {
        height: 23.7rem;
        overflow-y: scroll;
        width: 27rem;
        .item {
          position: relative;
          .block {
            color: var(--title-color);
            display: flex;
            .image {
              width: 45px;
              img {
                width: 45px;
                height: 45px;
                object-fit: cover;
                border-radius: 50%;
              }
            }
            span {
              font-size: 21px;
              padding-top: 0px;
              padding-left: 0px;
            }
            p {
              margin: 0;
              color: var(--title-color1);
              font-weight: 400;
              word-wrap: break-word;
              white-space: normal;
            }
          }
          .date {
            color: $info;
            font-weight: 500;
          }
          .title {
            margin-right: 9px;
            text-transform: capitalize;
          }
          &.read .notifications-info {
            .title {
              font-weight: 500;
              color: var(--title-color1);
            }
            .date {
              color: var(--title-color1);
            }
            p {
              color: var(--title-color1);
            }
          }
          &.unread:after {
            content: "";
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: $info;
            right: 11px;
            top: 12px;
            border-radius: 50%;
          }
          .dropdown-item {
            padding-top: 7px;
            padding-bottom: 7px;
            &:active {
              background-color: var(--borderGray);
            }
          }
        }
      }
      .dropdown-toggle {
        span {
          svg {
            width: 24px;
            height: 22px;
          }
        }
      }
      .notification-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 49px;
        button {
          width: 100% !important;
          border-radius: 0px 0px 10px 10px !important;
          font-size: inherit !important;
          background: none !important;
          border-top: solid 1px !important;
          border-color: var(--borderGray) !important;
          color: var(--text-color)!important;
        }
      }
    }
  }
}

html[data-theme="dark"] {
  .logo {
    img {
      filter: brightness(2);
    }
  }
}

.notification-list {
  $count: 150;
  @for $i from 1 through $count {
    .item {
      &:nth-child(#{$i}) {
        .image span.ant-avatar.ant-avatar-circle {
          @include random-bgr();
        }
      }
    }
  }
}
