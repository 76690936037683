.tag-default {
  background-color: #f0f0f0;
  border: 1px solid #e5e5e5;
  color: #9d9d9d;
  height: 22px;
  padding: 0 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
  @include border-radius(15px);
  display: inline-block;

  .tag-icon {
    display: flex;
    margin-left: 0.3125rem;
  }
}

.tag-default.yellow {
  background-color: #fff3cf;
  border-color: #fde399;
  color: #ffa907;
}

.tag-default.red {
  background-color: $danger;
  border-color: $danger;
  color: $white;
}
.tag-default.verified {
  background-color: $success;
}

.tag-default.borderless {
  border: none;
}

.tag-default.mt {
  &-0 {
    margin-top: 0 !important;
  }
  &-5 {
    margin-top: 0.3125rem !important;
  }
  &-10 {
    margin-top: 0.625rem !important;
  }
  &-15 {
    margin-top: 0.9375rem !important;
  }
}
.tag-default.mb {
  &-0 {
    margin-bottom: 0 !important;
  }
  &-5 {
    margin-bottom: 0.3125rem !important;
  }
  &-10 {
    margin-bottom: 0.625rem !important;
  }
  &-15 {
    margin-bottom: 0.9375rem !important;
  }
}
.tag-default.ml {
  &-0 {
    margin-left: 0 !important;
  }
  &-5 {
    margin-left: 0.3125rem !important;
  }
  &-10 {
    margin-left: 0.625rem !important;
  }
  &-15 {
    margin-left: 0.9375rem !important;
  }
}
.tag-default.mr {
  &-0 {
    margin-right: 0 !important;
  }
  &-5 {
    margin-right: 0.3125rem !important;
  }
  &-10 {
    margin-right: 0.625rem !important;
  }
  &-15 {
    margin-right: 0.9375rem !important;
  }
}
