.revision-history-wrapper {
  padding-bottom: 4px;
  padding-top: 4px;
  .rh-left{
    position: relative;
  }
  .hr-dot {
    height: 14px;
    width: 14px;
    background-color: $primary;
    @include border-radius(8px);
    &:after{
      content: "";
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: #eaeaea;
      top: 14px;
      left: 5px;
    }
  }

  .hr-vline {
    min-height: 65px;
    width: 3px;
    background-color: var(--lightGray);

    @media (min-width: 992px) {
      min-height: 45px;
    }
  }

  .rh-right {
    margin-left: 0.75rem;
    margin-top: -5px;

    .rhr-description {
      color: var(--title-color);
      p{
        margin-bottom: 2px;
        span{
          font-weight: 500;
        }
      }
    }

    .rhr-date {
      font-size: 0.8125rem;
      color: var(--title-color1);
    }
  }
}
.activity-log .modal-body {
  padding-right: 0;
}