.dropdown_body {
  position: absolute;
  background-color: currentColor;
  max-height: 280px;
  // margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #dee0e3;
  background-color: #ffffff;
  box-shadow: 0 5px 9px 0 rgba(180, 201, 215, 0.15);
  width: 100%;
  overflow: auto;
}

.dropdown_body::-webkit-scrollbar {
  width: 5px;
}

.dropdown_body::-webkit-scrollbar-thumb {
  background-color: #00adff;
  height: 50px;
}

.dropdown_body_regular {
  margin-top: 7px;
}
.dropdown_body_small {
  margin-top: 2px;
}
.dropdown_item {
  border-bottom: 1px solid #fafafa;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 400;
}
.dropdown_item_small {
  border-bottom: 1px solid #fafafa;
  padding: 10px 15px;
  font-size: 14px;
}

.dropdown_item:hover {
  background-color: #f3f2f7;
  cursor: pointer;
}
.dropdown_item_active {
  background-color: var(--hoverBg);
  cursor: pointer;
  color: var(--text-color);
}
.dropdown_item_active:hover,
.dropdown_item_active:focus {
  background-color: var(--hoverBg);
  color: var(--text-color);
}
.dropdown_item:last-child {
  border-bottom: 0px;
}
.dropdown_item_country_select_img {
  width: 50px;
}
.notFound {
  padding: 10px;
  font-style: italic;
  color: gray;
}

.secondaryLabel {
  color: var(--title-color1);
  font-size: 12px;
  word-break: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:first-child {
    max-width: 50%;
  }
  &:last-child {
    margin-left: 20px;
    max-width: 50%;
  }
}

.media_hide {
  transform: scale(0);
  transform-origin: 0% 0;
  opacity: 0;
}

.media_active {
  transform: scale(1);
  transition: all 0.25s cubic-bezier(0.5, 1.8, 0.9, 0.8);
  opacity: 1;
}

.fixed_option {
  border-bottom: 1px solid #fafafa;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  background-color: $primary;
  border-radius: 4px;
  margin: 5px;
  font-weight: 400;
  color: white;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.lawyerRegisterForm {
  .form-group {
    position: relative;
    .dropdown_body {
      overflow: hidden;
      .scroll-content {
        overflow-y: scroll;
        max-height: 210px;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: var(--cardBg1);
          height: 50px;
        }
      }
    }
  }
}
.beneficiaries_form .ant-select {
  padding: 2px 11px;
  border: none;
  height: auto;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  font-size: 0.9rem;
  display: inline-block;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0px;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.beneficiaries_form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 12px 10px;
  border: none;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
}
.ant-select-selection-search-input {
  position: absolute !important;
  top: 12px !important;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: inherit;
}
.beneficiaries_form .ant-select.form-control {
  padding: 0;
  @include forSize(phone) {
    margin-bottom: 0px;
    padding: 0;
  }
  @include forSize(phone-landscape) {
    margin-bottom: 0px;
    padding: 0;
  }
}
