.modal-center {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
}
.content {
  text-align: center;
}
@media screen and (min-width: 768px) {
  .modal-center:before {
    display: inline-block;
    vertical-align: middle;
    content: ' ';
    height: 100%;
  }
}
.dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.button {
  color: white;
  border: none;
  box-shadow: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px 32px;
  margin: 26px 5px 0 5px;
  cursor: pointer;
}
.cancel {
  background-color: #c1c1c1;
}
.icon {
  width: 60px;
}
.modal-center {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.4rem);
  transition: 0.8s;
}
