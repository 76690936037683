ul.time-line {
  padding: 0;
}

ul.time-line li {
  list-style: none;
  position: relative;
  padding-left: 54px;
  margin-bottom: 22px;
}

ul.time-line > li:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: $primary;
  top: 5px;
  left: 4px;
  border-radius: 20px;
}

ul.time-line > li:before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #d6d4cf;
  left: 13px;
  top: 22px;
}
li.event .description {
  background-color: var(--lightGray);
  padding: 13px;
  border-radius: 2px;
  border: solid 1px;
  border-color: var(--borderGray);
}
.event-title {
  width: 100%;
  position: relative;
  overflow: hidden;
}

li.event ul.action {
  padding: 0;
  display: block;
  float: right;
  position: absolute;
  right: -42px;
  top: 0;
  transition: 0.5s;
}

li.event ul.action li {
  display: inline-block;
  padding: 0px;
  margin-left: 12px;
}
ul.time-line li.event:hover ul.action {
  right: 0;
  transition: 0.5s;
}

.event-title h6 {
  display: inline-block;
}
li.event p {
  margin-bottom: 3px;
}
li.event .date:after {
  content: '';
  position: absolute;
  width: 28px;
  height: 28px;
  background-color: $primary;
  left: -1px;
  top: 0px;
  border-radius: 22px;
  opacity: 0.3;
}
li.event .description p {
  color: var(--text-color);
}
// .floating-but{
//     display: none;
//      @include forSize(phone) {
//         display: block;
//         }
// }
.fixed {
  @include forSize(tablet-portrait) {
    padding-bottom: 20px;
    padding-top: 24px;
  }
  button.btn {
    margin-left: 8px;
  }
}

.input-group-prepend {
  position: absolute;
  right: 1px;
}

.date .react-datepicker-wrapper {
  width: 100%;
  position: relative;
}

ul.time-line li.empty:after {
  display: none;
}

ul.time-line li.empty:before {
  display: none;
}

ul.time-line li.empty {
  padding: 38px;
}
ul.time-line li.empty img {
  width: 100% !important;
  height: auto !important;
  max-height: initial;
  margin-bottom: 26px;
}
textarea {
  resize: none;
}
.event-title h6 {
  width: 80%;
}

.life-story-container {
  .cardTitle {
    margin-bottom: 15px;
  }
  .empty {
    display: block;
  }
}
