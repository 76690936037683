.guest-login {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 75vh;
    .guest-login-form {
        padding: 18px;
        background-color: #ffffff;
        border-radius: 10px;
        h6 {
            margin-bottom: 17px;
            margin-top: 22px;
        }
        img {
            margin: 39px;
        }
        button {
            margin-bottom: 22px;
        }
        button.btn.btn-link {
            padding: 0;
            margin: 0;
        }
    }
}

header.guest-page-header {
    .guest-page {
        display: flex;
        align-items: center;
        padding: 0px 23px;
        position: fixed;
        width: 100%;
        background-color: #ffffff;
        top: 0;
        box-shadow: 0 0 14px 5px $shadowBlack;
        @include media-breakpoint-down(md) {
            padding: 10px 23px;
        }
        .logo {
            width: 25%;
            text-align: left;
            img {
                width: 200px;
                @include media-breakpoint-down(md) {
                    width: 100px;
                }
            }
        }
        .left-side {
            margin-left: auto;
            width: 75%;
            text-align: right;
            @include media-breakpoint-down(md) {
                width: 75%;
            }
            button {
                float: right;
            }
        }
    }
}
.content-area {
    padding: 10px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-top: 60px;
    @include media-breakpoint-down(md) {
        width: 100%;
        height: auto;
    }
    .log-info {
        width: 100%;
        .log-details {
            display: flex;
            padding: 12px;
            .log-details-list {
                text-align: left;
                margin-left: 11px;
                p {
                    margin-top: 6px;
                    margin-bottom: 6px;
                    span {
                        margin-right: 25px;
                        color: #535353;
                    }
                }
                h6 {
                    font-size: 15px;
                }
            }
        }
    }

    .player {
        width: 100%;
        height: auto;
        align-items: center;
        display: grid;
        video {
            width: 100%;
            border-radius: 11px;
            outline: none;
        }
        img {
            width: 100%;
            height: 100%;
        }
    }
}
footer.guest-page-footer {
    padding: 12px;
    p {
        margin: 0;
        button {
            margin: 0;
            padding: 0;
        }
    }
}
