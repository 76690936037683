.sideBar {
  width: 260px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  left: 0;
  height: 100%;
  margin-top: 60px;
  z-index: 8;
  box-shadow: 9px 0px 20px $shadowBlack !important;
  border: none;
  background-color: var(--cardBg);
  @include forSize(desktop-lg) {
    width: 220px;
    margin-top: 60px;
    height: calc(100vh - 60px);
  }
  li {
    font-size: 0.9375rem;
    border-radius: 30px 0px 0px 30px;
    &:hover {
      background-color: var(--hoverBg);
    }
    .ant-menu-sub {
      border-radius: 30px 0px 0px 30px;
    }
    span.anticon {
      font-size: 18px;
      vertical-align: baseline;
    }
    span.anticon.anticon-home {
      color: #ff5722;
    }
    &.active {
      background: var(--sideBarActiveBg);;
      border-right: 5px solid $primary;
      .chevron {
        display: none;
      }
    }
    &.withSubMenu {
      flex-direction: column;
      .mainMenu {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
      }
      .subMenu {
        width: 100%;
        padding-left: 17%;
        .menuItem {
          margin-bottom: 13px;
          font-size: 0.9375rem;
          &:last-child {
            margin-bottom: 0;
          }
        }
        a {
          color: #c4c4c4 !important;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &.open {
      .chevron {
        transform: rotate(90deg);
      }
    }
  }
}
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 18px;
}
.ant-menu-submenu > .ant-menu {
  background-color: var(--cardBg);
}

.ant-menu {
  color: var(--title-color);
  span.anticon.anticon-home {
    color: #ff5722;
  }
  span.anticon.anticon-file {
    color: #4caf50;
  }
  span.anticon.anticon-file-ppt {
    color: #f44336;
  }
  span.anticon.anticon-file-text {
    color: #dd03aa;
  }
  span.anticon.anticon-login {
    color: #3103ad;
  }
  span.anticon.anticon-chrome {
    color: #007bff;
  }
  span.anticon.anticon-message {
    color: #00e3af;
  }
  span.anticon.anticon-video-camera {
    color: #f54394;
  }
  span.anticon.anticon-user {
    color: #f4c414;
  }
  ul.ant-menu li span {
    vertical-align: baseline;
  }
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: $primary;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--activeBg);
  color: $primary;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 5px solid $primary;
}
ul.ant-menu.sideBar.sideBarclose {
  width: 82px;
}
.menu {
  margin-top: 4px;
  margin-left: 14px;
}
.menu button {
  margin: 0 !important;
  padding-bottom: 14px !important;
  width: 47px;
  height: 47px;
  font-size: 17px;
  border-radius: 100%;
  padding-top: 7px;
  margin-top: 3px !important;
  padding-left: 14px;
  background-color: white;
  border: none;
  box-shadow: none;
  color: black;
}
.mainPanel.main-panel-close {
  width: calc(100vw - 78px) !important;
  @include media-breakpoint-down(md) {
    width: calc(100vw - 0px) !important;
  }
}
.mainPanel.main-panel-open {
  @include forSize(ipad-landscape) {
    width: calc(100vw - 76px);
  }
  @include forSize(desktop-sm) {
    width: calc(100vw - 76px) !important;
  }
  @include media-breakpoint-down(md) {
    width: calc(100vw - 0px) !important;
  }
}

.ant-steps-item-tail {
  @include forSize(ipad-portrait) {
    width: 94% !important;
    margin-left: 10px;
  }
}

ul.ant-menu.sideBar {
  @include media-breakpoint-down(md) {
    width: 0px !important;
  }
}
ul.ant-menu.sideBar.sideBaropen {
  @include media-breakpoint-down(md) {
    width: 240px !important;
  }
}
ul.ant-menu.sideBar.sideBarclose {
  @include media-breakpoint-down(md) {
    width: 0px !important;
  }
}
.ant-steps-label-vertical .ant-steps-item-tail {
  @include media-breakpoint-only(md) {
    margin-left: 46px !important;
    width: 69% !important;
  }
}

.sideBar::-webkit-scrollbar-thumb {
  background-color: var(--cardBg);
  border-radius: 20px;
}
.sideBar::-webkit-scrollbar-track {
  background: var(--cardBg);
  border-radius: 20px;
}
.sideBar {
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--scroll-bar);
    }
    &::-webkit-scrollbar-track {
      background: var(--cardBg);
    }
  }
}
.ant-menu-item span span {
  font-size: 11px;
  color: $success;
  padding: 1px 5px;
  border-radius: 50px;
}