.avatar-row-wrapper {
  margin-bottom: 1.25rem;

  .av-name {
    font-size: 1rem;
    font-weight: 500;
  }

  .av-text-sm {
    font-size: 0.8125rem;
    font-weight: 400;
    color: var(--title-color1);
  }
}
.avatar-group-wrapper .ant-avatar {
  border: 1px solid #fff;
}
.avatar-group-wrapper .ant-avatar:not(:first-child) {
  margin-left: -10px;
}
span.ant-avatar {
  text-transform: uppercase !important;
}
