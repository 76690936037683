.no-data-wrapper {
  color: var(--title-color);
  text-align: center;
  .nd-title {
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 0.625rem;
    margin-bottom: 0.3125rem;
  }
  .nd-description {
    font-size: 0.9375rem;
    font-weight: 400;
  }
  img {
    width: 150px;
  }
}

.no-data {
  display: flex;
  align-items: center;
  border-radius: 9px;
  width: 100%;
  height: 57vh;
  .description {
    text-align: center;
    padding: 2rem;
    width: 100%;
    img {
      width: 190px;
      padding: 7px;
    }
    h5 {
      margin-bottom: 11px;
      margin-top: 9px;
    }
  }
  &.widget-full {
    height: 80vh !important;
    .description {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 150px;
      }
    }
  }
  &.widget {
    height: 45vh !important;
    .description{
      img{
        width: 132px;
      }
    }
  }
  &.unavailable {
    min-height: 76vh;
    margin-right: 12px !important;
    width: auto;
    @include media-breakpoint-down(sm) {
      margin-right: 12px !important;
    margin-left: 12px;
    margin-bottom: 20px;
    }
  }
}
