.pagination {
  li {
    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
.page-item.active .page-link {
  z-index: 3;
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.data-table-pagination {
  // padding: 10px 25px 0 5px;
  @include forSize(phone-landscape) {
    flex-direction: column;
    padding-top: 20px !important;
    padding-bottom: 20px;
  }
  @include forSize(phone) {
    flex-direction: column;
    padding-top: 20px !important;
    padding-bottom: 20px;
  }
}
