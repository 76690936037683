#account-manager {
  color: #707070;

  .am-header {
    font-weight: 700;
  }

  .am-section-wrapper {
    margin-bottom: 1.25rem;
  }

  .account-card-wrapper {
    background-color: #fff;
    border: 1px solid #efefef;
    @include border-radius(7px);
    padding: 1.25rem;
    margin-bottom: 0.3125rem;

    .aci-sm {
      font-size: 0.9375rem;
      //   margin-bottom: 0.3125rem;
    }

    .aci-lg {
      font-size: 1rem;
      font-weight: 500;
    }

    .btn {
      margin-bottom: 0;
      margin-top: 10px;

      @media (min-width: 992px) {
        margin-top: 0;
      }
    }
  }

  .account-card-wrapper.oa-card {
    .ac-name {
      flex: 1;
    }
    .ac-id {
      flex: 3;
    }
  }
}
