.probate-will-wrapper {
  .col-xl-4 {
    @media (min-width: 1280px) {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    @media (min-width: 1920px) {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  .col-xl-8 {
    @media (min-width: 1280px) {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    @media (min-width: 1920px) {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
  .ant-avatar {
    line-height: 1.5715 !important;
    border: solid 1px;
    border-color: var($borderGray);
    @include forSize(phone) {
      height: 55px !important;
      width: 55px !important;
      line-height: 1.3 !important;
    }
  }

  .h-100 {
    @media (min-width: 1024px) {
      height: auto !important;
    }
    @media (min-width: 1280px) {
      height: 100% !important;
    }
  }
}
.copy-clipboard {
  display: flex;
  @include media-breakpoint-down(md) {
    display: block;
  }
  span {
    width: 57%;
    padding: 4px 0px 4px;
    align-items: center;
    display: flex;
  }
  .copy-clipboard-wrapper {
    width: 100%;
    display: flex;
    border-radius: 4px;
    align-items: center;
    padding: 4px 4px 4px;
    .copy-icon {
      margin-left: auto;
      padding: 2px 5px;
      border-radius: 3px;
      &:focus {
        background-color: $primary;
      }
      &:hover {
        background-color: #bbbbbb;
      }
    }
    &:hover {
      background-color: var(--hoverBg);
    }
    span.copy-title {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 85%;
    }
    span {
      display: block;
    }
  }
}

.will-summary-info {
  p {
    margin-bottom: 0 !important;
  }
  .item {
    margin-bottom: 9px;
    .list {
      margin-top: 6px;
    }
  }
}
.death-cancel.modal{
  backdrop-filter: blur(0.62rem);
  text-align: center;
}