.card_scroll {
  min-height: calc(100vh - 160px);
  position: relative;
  overflow: auto;
  padding-bottom: 100px;
}
.ant-steps-label-vertical {
  .ant-steps-item-content {
    width: 80px;
  }
  .ant-steps-item-tail {
    padding: 4.5px 0;
    margin-left: 56px;
  }
}
.ant-steps-label-vertical .anticon {
  display: none;
}
.create-will-radio {
  white-space: nowrap;
}
.ant-steps-item {
  &.ant-steps-item-finish {
    .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: $primary;
    }
    &:nth-child(1) {
      span.ant-steps-icon {
        &::after {
          content: "1";
        }
      }
    }
    &:nth-child(2) {
      span.ant-steps-icon {
        &::after {
          content: "2";
        }
      }
    }
    &:nth-child(3) {
      span.ant-steps-icon {
        &::after {
          content: "3";
        }
      }
    }
    &:nth-child(4) {
      span.ant-steps-icon {
        &::after {
          content: "4";
        }
      }
    }
    &:nth-child(5) {
      span.ant-steps-icon {
        &::after {
          content: "5";
        }
      }
    }
    &:nth-child(6) {
      span.ant-steps-icon {
        &::after {
          content: "6";
        }
      }
    }
    &:nth-child(7) {
      span.ant-steps-icon {
        &::after {
          content: "7";
        }
      }
    }
    &:nth-child(8) {
      span.ant-steps-icon {
        &::after {
          content: "8";
        }
      }
    }
  }
  .ant-steps-item-container {
    text-align: center;
    display: inline-block;
    .ant-steps-item-icon {
      display: inline-block !important;
      margin: 0;
    }
    .ant-steps-item-title {
      padding-right: 0;
      font-weight: 400;
      font-size: 14px;
      color: var(--text-color) !important;
      &::after {
        top: -15px;
      }
    }
  }
  &.ant-steps-item-active {
    .ant-steps-item-title {
      color: $primary !important;
    }
  }
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #99a0ac;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--borderGray);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: var(--borderGray);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--text-color);
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--cardBg);
  border-color: $primary;
}
.ant-steps-item-process .ant-steps-item-icon {
  background: $primary;
  border-color: $primary;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: $primary;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background: $primary;
}
.ant-steps-item-wait .ant-steps-item-icon {
  border-color: var(--borderGray);
  background-color: var(--cardBg);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #99a0ac;
}

.steps-content {
  h3 {
    span {
      color: $primary;
    }
  }
}
.steps-action {
  position: relative;
  left: auto;
  right: auto;
  margin-top: 62px !important;
  margin-left: auto;
  margin-right: auto;
  button {
    min-width: 150px;
    height: auto;
    margin: 0 !important;
  }
}
.min_btn_wrp {
  button {
    min-width: 220px;
    padding: 20px 15px !important;
    height: auto;
    color: #535353;
    background-color: #ebebeb;
    border: 1px solid #ebebeb;
    text-shadow: none;
    border-radius: 4px;
    display: block;
    text-align: center;
    cursor: pointer !important;
    @media (max-width: 575.98px) {
      min-width: auto;
    }
    &:hover {
      color: $primary;
    }
    &.ant-tabs-tab-active,
    &:focus,
    &.ant-tabs-tab-active:focus {
      color: $white;
      background-color: $primary;
      outline: none;
    }
  }
  .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
  .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
  }
}
.section_header {
  border-bottom: 1px solid #dee2e6;
}
.lawyer_btn_wrp {
  .btn {
    min-width: 250px;
  }
}
.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}
.collaspe_wrp {
  .ant-collapse {
    border: none;
    background: transparent;
    .ant-collapse-item {
      background: transparent;
      margin-bottom: 5px;
      border: 1px solid var(--borderGray) !important;
      span.anticon.anticon-right.ant-collapse-arrow {
        border: 1px solid #c3c3c3;
        height: 25px;
        width: 25px;
        padding: 6px;
        border-radius: 50%;
        svg {
          color: #c3c3c3;
        }
      }
    }
  }
}

// Finalize Section
.agreement_wrp {
  border: 1px solid #bcbcbca0;
  padding: 40px;
  h4 {
    margin-bottom: 0;
  }
  ol {
    margin: 0;
    padding: 0;
    li {
      p {
        font-weight: 300;
        font-size: 14px;
        b {
          font-weight: 500;
        }
      }
      .appointment_sub {
        margin-top: 20px;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}

.lawyer_list_item {
  box-shadow: 0px 3px 30px #0000000f;
  padding: 15px;
  border-radius: 8px;
}

.character-limit {
  span {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
.character-limit-w-200 {
  span {
    display: block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
.beneficiaries_form {
  .table-row {
    button.btn {
      @include media-breakpoint-down(md) {
        margin: 0;
        margin-bottom: 12px;
      }
    }
  }
  .tbody {
    .custom-table-responsive {
      .table-row {
        padding-left: 0;
        padding-right: 0;
        @include media-breakpoint-down(md) {
          padding-left: 20px;
          padding-right: 20px;
        }
        .edit-option {
          @include media-breakpoint-up(lg) {
            padding-right: 11px;
          }
          @include media-breakpoint-down(md) {
            margin-top: 6px;
          }
        }
        .option {
          button.btn.edit-btn {
            margin-right: 20px;
          }
        }
        .hide {
          @include media-breakpoint-up(lg) {
            display: none;
          }
          @include media-breakpoint-down(md) {
            display: block;
          }
        }
      }
      .table-row.edit {
        @include media-breakpoint-down(md) {
          padding-left: 0px;
          padding-right: 0px;
        }
        .form-group.smallInput.first {
          margin-left: 10px;
          @include media-breakpoint-down(md) {
            margin-left: 1px;
          }
        }
        @include media-breakpoint-down(md) {
          padding-left: 0px;
          margin-right: 0px;
        }
      }
      @include media-breakpoint-down(md) {
        padding-left: 0px;
      }
      .table-data {
        .first-column {
          padding-left: 23px;
          @include media-breakpoint-down(md) {
            padding-left: 0px !important;
          }
        }
        .invite-text {
          padding-top: 7px;
          @include forSize(ipad-pro-portrait) {
            margin-bottom: -3px;
          }
          @include forSize(ipad-pro-landscape) {
            margin-bottom: 7px;
          }
          @include forSize(ipad-landscape) {
            margin-bottom: 0px;
          }
        }
        @include forSize(ipad-pro-landscape) {
          margin-bottom: -0px;
          padding-top: 22px;
        }
        @include forSize(ipad-pro-portrait) {
          margin-bottom: -8px;
        }
      }
      .hide {
        @include media-breakpoint-up(lg) {
          display: none;
        }
        @include media-breakpoint-down(md) {
          display: block !important;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  .beneficiaries_form {
    .ant-select.form-control {
      margin-bottom: 16px;
    }
    .table-row.table-data {
      padding: 20px 40px;
    }
  }
  .steps-content {
    .pt_40 {
      padding-top: 0;
    }
    h3 {
      margin-top: 0;
    }
  }
  .steps-action {
    button {
      min-width: 48% !important;
      height: auto;
      &:first-child {
        margin-left: 0 !important;
      }
    }
  }

  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    display: block !important;
  }
  button#rc-tabs-0-tab-2 {
    margin-top: 10px;
  }
  .min_btn_wrp {
    .ant-tabs-content {
      .mt_30 {
        margin-top: 1rem;
      }
    }
    button {
      width: 100%;
      margin-top: 10px;
    }
    p.mt_30.pl_40.pr_40 {
      padding: 0 !important;
      margin-top: 0;
    }
  }
}
@media (max-width: 767.98px) {
  .ant-steps-label-vertical {
    margin-bottom: 2rem;
    .ant-steps-item-tail {
      padding: 0;
      margin-left: 20px;
      top: 10px;
    }
    .ant-steps-item-content {
      display: none;
    }
    .ant-steps-item-process .ant-steps-item-icon {
      background: $primary;
      .ant-steps-icon {
        color: white;
        font-weight: 500;
      }
    }
    .ant-steps-item-icon {
      width: 20px;
      height: 20px;
      border: none;
      font-size: 12px;
      line-height: 22px;
      background: #fdead0;
      .ant-steps-icon {
        color: $primary;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .ant-steps-label-vertical {
    .ant-steps-item-title {
      font-size: 13px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .ant-steps-label-vertical {
    .ant-steps-item-title {
      font-size: 11px !important;
    }
    .ant-steps-item-content {
      width: 60px;
    }
  }
}

_::-webkit-full-page-media,
_:future,
:root label.radio-inline {
  padding-top: 1px;
}

// _::-webkit-full-page-media,
// _:future,
// :root input[type="radio"]:after {
//     position: absolute;
// }

.btn.orange.hollow {
  @include forSize(phone) {
    padding: 8px;
  }
}
.row.d-flex.align-items-center {
  @include forSize(phone) {
    padding: 17px;
    padding-bottom: 0;
  }
  @include forSize(phone-landscape) {
    padding: 17px;
    padding-bottom: 0;
  }
}
.ant-tabs-nav .ant-tabs-tab {
  @include forSize(galaxy-fold) {
    display: block;
    text-align: center;
  }
}
.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  @include forSize(galaxy-fold) {
    display: none !important;
  }
}
.beneficiaries_form {
  .ant-select.form-control {
    border: none;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: auto !important;
    border-radius: 4px;
    border: 1px solid var(--borderGray) !important;
    background-color: var(--inputsBg);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: auto;
  }
  .ant-select-selection-placeholder {
    color: #7e7e7e;
    opacity: 1;
    font-weight: 300;
  }
}

.steps-content svg {
  height: 100%;
}
// Assets section
.assets-list {
  .ant-collapse-header {
    h6.character-limit {
      font-size: 15px;
    }
    p {
      margin: 0;
      border: solid 1px;
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 19px;
      padding-top: 2px;
      background-color: theme-color-level(primary, -10);
      border-color: theme-color-level(primary, -8);
      color: $primary;
      @include media-breakpoint-down(sm) {
        font-size: 11px;
        padding: 5px;
      }
    }
  }
  .ant-collapse-content-box {
    background-color: var(--cardBg1);
    pre {
      font-family: "Heebo", sans-serif;
      white-space: pre-line;
      word-break: break-word;
    }
  }
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  @include media-breakpoint-down(sm) {
    padding: 12px 7px !important;
    padding-right: 43px !important;
  }
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
  background: none;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  @include media-breakpoint-down(sm) {
    right: 10px;
  }
}
.modal-dialog.modal-lg .table-row button.btn.orange {
  @include media-breakpoint-down(md) {
    margin: 0;
    margin-top: 5px;
  }
}
.assets-list {
  .ant-collapse {
    .ant-collapse-item {
      @include media-breakpoint-down(md) {
        border: none !important;
        box-shadow: 0 0 6px 1px #0000001a;
        border-radius: 4px;
        margin-bottom: 15px;
      }
    }
  }
}
.add-assets.modal {
  p {
    color: var(--title-color1);
    font-style: italic;
    margin-top: -7px;
    font-size: 13px;
  }
}
// Executor
button.ant-btn.executor-skip-but.ant-btn-primary {
  margin-left: auto !important;
  background-color: white;
  color: #403f3f;
  border: white;
  box-shadow: none;
  @include forSize(phone) {
    position: absolute;
    top: 50px;
    left: 27%;
    margin-bottom: 20px;
  }
}
.row.align-items-center {
  margin-top: -1px;
  &:focus {
    outline-color: #ff5722;
  }
  .option {
    margin-bottom: 13px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 0px;
    }
  }
  .text-limit1 {
    margin-top: 9px;
  }
}
.row.align-items-center.add-form {
  padding: 0;
  button {
    margin: 0;
  }
}

.form-group.smallInput.null .label {
  display: inline-flex;
}

.form-group.smallInput.null .label span.required {
  display: flex;
  width: auto;
}
.excutor {
  margin-bottom: 10px;
  .table-custom {
    .table-body {
      .tbody {
        .table-row .table-data {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
          @include media-breakpoint-down(md) {
            margin-left: 8px;
            margin-right: 8px;
          }
        }
        .row.align-items-center.border {
          background-color: $white;
          @include media-breakpoint-down(md) {
            padding-top: 26px;
            margin-bottom: 15px;
            border: none !important;
            box-shadow: 0 0 6px 1px #0000001a;
            border-radius: 4px;
          }
        }
        .email {
          @include media-breakpoint-down(md) {
            color: $primary;
          }
        }
      }
    }
  }
}

// Summary Section
ul.summary-info-list {
  li {
    width: 100% !important;
    .infomation-box {
      align-items: end;
      padding: 7px;
      border-radius: 2px;
      padding-left: 0;
      padding-right: 0;
      @include media-breakpoint-down(md) {
        align-items: flex-start;
      }
      .img {
        .name {
          background-color: #ff932452;
          padding: 14px;
          border-radius: 50%;
          width: 45px;
          height: 45px;
          text-align: center;
          padding-top: 10px;
          color: $white;
          margin-right: 13px;
          text-transform: uppercase;
          font-size: 22px;
          font-weight: 400;
        }
      }
      .info-user {
        width: 100%;
        @include forSize(ipad-landscape) {
          display: block !important;
        }
        .right-side {
          margin-left: auto;
          min-width: 144px;
          @include media-breakpoint-down(sm) {
            width: 100%;
            min-width: 100%;
            padding-right: 20px;
          }
          .dp-name {
            margin-top: 18px;
            @include media-breakpoint-down(sm) {
              margin-top: 0px;
            }
            @include media-breakpoint-down(md) {
              margin-top: 0px;
            }
            @include forSize(ipad-landscape) {
              margin-top: 0px;
            }
          }
        }
        .left-side {
          word-break: break-all;
          margin-right: 11px;
          width: 223px;
          min-width: 223px;
          @include media-breakpoint-down(sm) {
            width: 100%;
            min-width: 100%;
            padding-right: 20px;
          }
        }
      }
    }
    .border-bottom-only {
      border-bottom: solid 1px;
      border-color: #e9e9e9;
    }
  }
}
ul.summary-info-list {
  $count: 150;
  @for $i from 1 through $count {
    li {
      &:nth-child(#{$i}) {
        .img h5.name {
          @include random-bgr();
        }
      }
    }
  }
}
.summary-content {
  .ant-collapse {
    border-color: var(--borderGray);
    background: none;
    .ant-collapse-item {
      border-color: var(--borderGray);
      background-color: var(--cardBg1);
      .ant-collapse-header {
        display: flex;
        align-items: center;
        &:hover {
          background-color: var(--hoverBg);
        }
        .ant-collapse-arrow {
          border: solid 1px;
          border-color: var(--borderGray);
          padding: 8px;
          border-radius: 50%;
          color: var(--text-color);
          &:hover {
            background-color: theme-color-level(primary, -10);
            border-color: theme-color-level(primary, -8);
          }
        }
        button.btn {
          padding: 5px;
          border-color: var(--borderGray);

          &:focus {
            box-shadow: none;
            color: $primary;
            background-color: theme-color-level(primary, -10);
          }
          &:hover {
            border-color: theme-color-level(primary, -8);
          }
        }
        span.title {
          color: var(--title-color);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          @include media-breakpoint-only(xs) {
            width: 100%;
          }
          @include media-breakpoint-only(sm) {
            width: 33%;
          }
        }
        .ant-collapse-extra {
          margin-left: auto;
        }
        .error{
          color: $danger;
          margin-right: 5px;
        }
        span.updated.text-xs {
          background-color: theme-color-level(warning, 0);
          border-color: theme-color-level(warning, 0);
          color: var(--text-color);
          padding: 1px;
          border-radius: 50px;
          padding-left: 8px;
          padding-right: 8px;
      }
      }
      .ant-collapse-content-box {
        .content {
          text-align: left;
          p {
            margin: 0;
          }
          ul {
            padding: 0;
            margin-top: 10px;
            margin-bottom: 10px;
            li {
              display: inline-block;
              padding: 0;
              padding-right: 2px;
              margin-bottom: 10px;
              vertical-align: top;
              h6 {
                padding: 0;
                text-transform: capitalize;
                s span {
                  font-weight: 300;
                  font-size: 12px !important;
                  color: #858585;
                }
              }
              span.date {
                font-size: 12px;
                color: #60564a82;
              }
              p.lable {
                font-size: 12px;
                margin-bottom: 3px;
                span {
                  border: solid 1px;
                  border-color: theme-color-level(primary, -8);
                  background-color: theme-color-level(primary, -10);
                  padding-left: 7px;
                  padding-right: 10px;
                  border-radius: 16px;
                }
              }
            }
          }
        }
        @include media-breakpoint-down(md) {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
    .ant-collapse-item-active {
      .ant-collapse-header {
        background-color: theme-color-level(primary, -10);
        color: $primary;
        padding-bottom: 12px;
        .ant-collapse-arrow {
          background-color: #fafbfb;
          border-color: theme-color-level(primary, -8);
          color: $primary;
          &:hover {
            background-color: theme-color-level(primary, -10);
            border-color: theme-color-level(primary, -8);
          }
        }
        button.btn {
          border-color: theme-color-level(primary, -8);
          color: $primary;
        }
      }
    }

    .ant-collapse-item-active.header-error {
      .ant-collapse-header {
        background-color: theme-color-level(danger, -11);
        color: $danger;
        padding-bottom: 12px;
        .title {
          color: $danger;
        }
        .ant-collapse-arrow {
          background-color: #fafbfb;
          border-color: $danger;
          color: $danger;
          border-color: theme-color-level(danger, -8);
          &:hover {
            background-color: theme-color-level(danger, -8);
            border-color: theme-color-level(danger, -8);
          }
        }
        button.btn {
          border-color: theme-color-level(danger, -8);
          color: $primary;
        }
      }
    }
    .ant-collapse-item.header-error {
      background-color: var(--dangerSoft);
      color: $primary;
    }
  }
  .attachments-info {
    .ant-collapse-item {
      .ant-collapse-header {
        span.title {
          @include media-breakpoint-only(xs) {
            width: 47%;
          }
        }
      }
    }
    button.btn.btn-primary-soft.ml-a {
      @include media-breakpoint-only(xs) {
        margin-top: 10px;
      }
  }
  }
}
.section .border-b {
  font-size: 1.1rem;
  background-color: #b3b4b630;
  color: #191919;
  font-weight: 400;
  padding: 7px 5px 5px 5px;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.section ul {
  margin-left: 5px;
}
.section.no-allocations {
  .empty-data {
    color: $danger;
    padding-bottom: 5px;
    padding-top: 5px;
    font-style: italic;
  }
  svg {
    width: 0.7em !important;
    margin-top: -5px;
  }
}
// Finalize page
.notarize-option-box {
  border: solid 1px;
  border-radius: 9px;
  border-color: var(--borderGray);
  padding: 17px;
  margin-bottom: 15px;
  height: auto;
  min-height: 100%;
  &.active {
    background-color: var(--cardBg1);
    border-color: $primary;
  }
  &:hover {
    background-color: var(--hoverBg);
  }
  .link {
    color: #6381fc;
  }
  .text-cente {
    text-align: center;
  }
  h6 {
    border-bottom: solid 1px;
    border-color: var(--borderGray);
    padding-bottom: 10px;
  }
  p {
    margin-top: 10px;
    margin-bottom: 10px;
    span {
      font-weight: 600;
    }
  }
  img {
    margin-bottom: 17px;
    margin-top: 13px;
  }
  button svg {
    width: 19px;
    margin-top: -6px;
  }
  .progress {
    margin-top: 6px;
  }
  @include media-breakpoint-down(md) {
    min-height: auto;
  }
}
.notarize-option-box .ant-upload.ant-upload-drag {
  height: auto;
  background-color: var(--cardBg1);
}
.notarize-option-box .upload-button {
  float: right;
  margin-top: 6px;
  background-color: $primary;
  border-color: $primary;
  padding: 6px 25px;
}
.summary-info {
  .section-header {
    margin-bottom: 15px;
    margin-top: 2px;
  }
}
.section-header {
  margin-bottom: 19px;
  align-items: center;
  svg {
    font-size: 20px;
    margin-top: -7px;
  }
}
.summary-content  {
  nav.nav.nav-tabs{
    @include media-breakpoint-down(sm) {
    margin-top: 10px;
    }
  }
  .tab-content{
    padding-top: 0;
  }
}
.invite-lawyer.modal {
  img {
    width: 100%;
    margin-bottom: 17px;
  }
  .modal-header {
    border: none;
  }
}

/* Ishara - Create Will - Summary Confirmation Modal */
.summary-content-confirm-alert {
  .modal-sm {
    @media (min-width: 576px) {
      max-width: 430px;
    }
  }
}

// pdf download page break
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
@page {
  size: auto;
  margin: 8mm;
}
