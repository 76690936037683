.settings-wrapper {
  color: var(--title-color);
  margin-bottom: 1.8125rem;

  &:last-child {
    margin-bottom: 0;
  }

  .header-wrapper {
    border-bottom: 1px solid var(--borderGray);
    margin-bottom: 1.875rem;
    padding-bottom: 0.4375rem;

    .header {
      font-weight: 500;
    }
    .btn {
      background-color: #ebebeb;
      border: 1px solid #ebebeb;
      color: var(--text-color);

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .section-wrapper {
    margin-top: 1.875rem;
    .section-row .sr-info {
      font-weight: 500;
      @include media-breakpoint-down(xs) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    }
    .section-row {
      margin-bottom: 1.125rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    // .section-row .sr-info:nth-child(1) {
    //   flex: 1;
    // }
    // .section-row .sr-info:nth-child(2) {
    //   flex: 4;
    // }
  }

  /* Edit */
  .edit-avatar-wrapper {
    position: relative;
    @include media-breakpoint-down(xs) {
      text-align: center;
    }
    .edit-avatar {
      height: 100px;
      width: 100px;
      @include border-radius(85px);
      background-color: theme-color-level(primary, -10);
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color:$primary;
      font-size: 1.125rem;
      cursor: pointer;

      @media (min-width: 992px) {
        right: 0;
        left: auto;
      }
      @include media-breakpoint-down(xs) {
        text-align: center;
      }
    }
  }
  .edit-fields-wrapper {
    position: relative;
    background-color: var(--cardBg1);
    padding: 1.875rem;
    @include media-breakpoint-down(xs) {
      padding: 0.875rem;
    }
    input {
      padding-right: 46px;
    }
    i {
      position: absolute;
      top: 2px;
      right: 6px;
      background-color: #f0f8ff00;
      padding: 9px;
      &:hover {
        background-color:#f7f7f7;
        cursor: pointer;
      }
    }
    @include border-radius(12px);
    margin-right: 0;

    @media (min-width: 992px) {
      margin-right: 2.5rem;
    }

    .col-form-label {
      font-weight: 500;
    }
    .buttons-wrapper {
      text-align: right;
      padding: 0;
      .gray {
        background-color: #ebebeb;
        border: 1px solid #ebebeb;
        color: #99a0ac;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      .orange {
        margin-left: 0.625rem;
      }
    }

    .ef-text-wrapper {
      background-color: #ebebeb;
      padding: 0.9375rem;
      color: #000;
      @include border-radius(12px);
      margin-bottom: 1.875rem;
    }
  }
  .icon-box {
    width: 100px;
    height: 100px;
    text-align: center;
    align-items: center;
    display: grid;
    background-color: theme-color-level(primary, -10);
    border-radius: 50%;
    font-size: 40px;
    color: $primary;
    margin-left: auto;
    @include media-breakpoint-down(sm) {
      margin-left: auto;
      margin-right: auto;
    }
  }
  p {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
  .security-login-wrapper {
    .section-row .sr-info {
      @include media-breakpoint-down(xs) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 298px;
      }
    }
  }
  .text-sm-right {
    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }
}

.personal-info-wrapper {
  .profile-image {
    background-color: theme-color-level(primary, -10);
    color: $primary;
    span {
      font-size: 2.5rem;
      color: $primary;
    }
  }
}

.edit-personal-info-wrapper {
  .profile-image {
    background-color: theme-color-level(primary, -10);
    color: $primary;
    span {
      font-size: 4rem;
      color: $primary;
      margin-top: -11px;
      font-size: 2.5rem;
      text-align: center;
      align-items: center;
      display: contents;
    }
  }
}

.user-profile-image {
  position: relative;
  display: initial;
  .edit-avatar {
    position: absolute;
    top: 59px;
    right: -4px;
    background-color: var(--cardBg1);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    text-align: center;
    align-items: center;
    display: grid;
    border: solid 1px;
    border-color: var(--borderGray);
    font-size: 19px;
    box-shadow: 0 0 3px 0px #0000004f;
    &:hover {
      background-color: var(--cardBg);
      cursor: pointer;
    }
  }
}
