.pw-right-wrapper {
  @include media-breakpoint-down(sm) {
    -webkit-box-shadow: 0px -7px 17px -4px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px -7px 17px -4px rgba(0, 0, 0, 0.22);
    box-shadow: 0px -7px 17px -4px rgba(0, 0, 0, 0.22);
    padding-top: 4px;
  }
  .pwr-header {
    border-bottom: 1px solid var(--borderGray);
    padding-right: 16px;
    padding-bottom: 7px;
    align-items: center;
    @include media-breakpoint-down(sm) {
      padding: 12px;
      background-color: var(--cardBg1);
      padding-bottom: 31px;
      -webkit-box-shadow: 0px -7px 17px -4px rgba(0, 0, 0, 0.22);
      -moz-box-shadow: 0px -7px 17px -4px rgba(0, 0, 0, 0.22);
      box-shadow: 0px -7px 17px -4px rgba(0, 0, 0, 0.22);
    }
    .avatar-row-wrapper {
      margin-right: 10px;
      cursor: pointer;
      &.active {
        h6 {
          color: $primary;
        }
        .ant-avatar-icon {
          border: solid 2px;
          border-color: $primary;
        }
      }
    }
    .label-bg-success {
      padding: 9px;
      color: $success;
      background-color: theme-color-level(success, -10);
      border-color: theme-color-level(success, -8);
      border-radius: 8px;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: 500;
      svg {
        margin-top: -4px;
      }
    }
    .label-bg-warning {
      padding: 9px;
      color: $warning;
      background-color: theme-color-level(warning, -10);
      border-color: theme-color-level(warning, -8);
      border-radius: 8px;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: 500;
      svg {
        margin-top: -4px;
      }
    }
    .label-bg-danger {
      padding: 9px;
      color: $danger;
      background-color: theme-color-level(danger, -10);
      border-color: theme-color-level(danger, -8);
      border-radius: 8px;
      border-radius: 5px;
      font-size: 1rem;
      font-weight: 500;
      svg {
        margin-top: 2px;
        width: 18px;
        fill: $danger;
      }
    }

    .av-name {
      font-size: 1.125rem;
    }
  }

  .pwr-body {
    .tabLine {
      box-shadow: none;
      border-bottom: 1px solid var(--borderGray);
      .nav-link {
        font-weight: 400;
        color: var(--text-color);
        &.active {
          color: $primary;
        }
      }
    }

    .nav-item {
      flex: 1;
      text-align: center;
    }

    .tab-content {
      padding: 0.9375rem;
      height: 62vh;
      overflow-y: auto;
      overflow-x: hidden;
      border: solid 1px;
      border-color: var(--borderGray);
      border-bottom: none;
      border-top: none;
      .no-data img {
        width: 77px;
    }
    }
  }

  .avatar-row-wrapper {
    margin-top: 0.9375rem;
    margin-bottom: 0.9375rem;

    @media (min-width: 992px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .btn.gray {
    background-color: #ebebeb;
    color: #99a0ac;
    border-color: #ebebeb;
    margin-left: 0.625rem;
  }
  .no-data.unavailable {
    @include media-breakpoint-down(sm) {
      margin-top: 10px;
    }
  }
}
span.no-data-label-sub {
  font-style: italic;
  color: var(--title-color1) !important;
}
