// Font
$primary-font: "Heebo", sans-serif;

// Default Colors
$default: #f9f9ff;
$primary: #ff9234;
$primary-hover: #f07c17;
$secondary: #888585;
$success: #24b97f;
$info: #38a4ff;
$warning: #ffc107;
$danger: #fb3527;
$link: #38a4ff;
$white: #ffffff;

$lightGray: #191e3a;
$hoverBg: #1b2e4b;
$bodyBg: #060818;
$cardBg: #0e1726;
$hoverCard: #e6e6e6;
$cardBg1: #191e3a;
$inputsBg: #1b2e4b;
$borderGray: #51536547;
$inputPlaceholder: #acb0c4;
$title-color: #203559;
$text-color: #506690;

// Side Bar Color Theme
$sideBarActiveBg: #1c2c4896;

$theme-colors: (
  "indigo-custom": #6610f2
);

html {
  --primary:#ff9234;
  --secondary:#888585;
  --success:#24b97f;
  --info:#38a4ff;
  --warning:#ffc107;
  --danger:#fb3527;
  --link:#38a4ff;

  --primarySoft:#ff923426;
  --secondarySoft:#8885853a;
  --successSoft:#24b98041;
  --infoSoft:#38a5ff42;
  --warningSoft:#ffc1073d;
  --dangerSoft:#fb352742;
  --linkSoft:#38a5ff36;

  --indigo:#6610f2;
  --white:#ffffff;

  --lightGray: #f7f7f7;
  --hoverBg: #e7e7e7;
  --bodyBg: #f8f8f8;
  --cardBg: #fff;
  --hoverCard: #e6e6e6;
  --cardBg1: #fafafa;
  --inputsBg: #fff;
  --borderGray: #dee2e6;
  --inputPlaceholder: #7c7c7c;
  --title-color: #203559;
  --title-color1: #203559a6;
  --text-color: #203559;
  --scroll-bar:#bbbbbb;
  --loaderBackgroundColor:#f3f3f3;
  --loaderForegroundColor:#ecebeb;

  // Side Bar Color Theme
  --activeBg: #ff923426;
}
html[data-theme="dark"] {
  --primary:#655dfe63;
  --secondary:#888585;
  --success:#24b98065;
  --info:#38a5ff69;
  --warning:#ffc10760;
  --danger:#fb352756;
  --link:#38a5ff56;

  --primarySoft:#655dfe50;
  --secondarySoft:#8885853a;
  --successSoft:#24b98041;
  --infoSoft:#38a5ff42;
  --warningSoft:#ffc1073d;
  --dangerSoft:#fb352742;
  --linkSoft:#38a5ff36;

  --indigo:#6710f256;
  --white:#ffffff77;

  --lightGray: #191e3a;
  --hoverBg: #1b2e4b;
  --bodyBg: #060818;
  --cardBg: #0e1726;
  --hoverCard: #e6e6e6;
  --cardBg1: #191e3a;
  --inputsBg: #0e1726;
  --borderGray: #51536547;
  --inputPlaceholder: #acb0c4;
  --title-color: #e0e6ed;
  --title-color1: #e0e6ed;
  --text-color: #506690;
  --scroll-bar:#bebebe;
  --loaderBackgroundColor:#191e3a;
  --loaderForegroundColor:#0e1726;

  // Side Bar Color Theme
  --activeBg: #ff923426;
}

// Shadow
$shadowBlack: #0000000f;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;
