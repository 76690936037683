.cardDefault {
  width: 100%;
  background-color:var(--cardBg);
  border-radius: 8px;
  box-shadow: 0px 3px 30px $shadowBlack;
  padding-bottom: 30px;
  margin-top: 10px;
  .titleRow {
    display: flex;
    justify-content: space-between;
    .cardTitle {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--title-color);
      // padding: 20px 25px 0;
    }
    .link {
      text-decoration: underline;
      font-size: 0.82rem;
    }
  }
  &.full-page {
    .empty {
      min-height: calc(100vh - 21rem);
      display: grid;
      place-items: center;
    }
  }
  &.section-wrp {
    position: relative;
    .empty {
      min-height: 300px;
      display: grid;
      place-items: center;
    }
  }
  .empty {
    text-align: center;
    width: 100%;
    place-items: center;
    min-height: calc(100vh - 40rem);
    display: grid;
    img {
      max-height: 155px;
    }
    h3 {
      color: var(--title-color);
      margin: 10px 0 5px;
      font-size: 20px;
    }
    p {
      color: var(--title-color);
      margin-bottom: 0;
      font-weight: 300;
    }
    .description {
      margin-top: 5px;
    }
    & + .buttonSection {
      margin-top: 15px !important;
    }
    &.twoCol {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .emptyLeft {
        text-align: left;
        line-height: 1;
        .title {
          font-size: 2.3rem;
          font-weight: 600;
          color: $primary;
        }
      }
    }
  }
}
