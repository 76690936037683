.info_banner {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
  position: relative;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size: 14px;
}
