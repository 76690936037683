.time-picker-slot {
    border: solid 0.5px;
    border-radius: 3px;
    border-color: var(--borderGray);
    span {
        padding: 10px;
        border-left: solid 0.5px;
        border-color: var(--borderGray);
        display: block;
        width: 25%;
        text-align: center;
    }
    span:hover {
        background-color: $primary-hover;
        cursor: pointer;
        color: $white;
    }
    & .active {
        background-color: $primary;
        color: $white;
    }
    .active:hover {
        background-color:$primary-hover;
    }
    & span:first-child {
        border-left: none;
    }
}
.time-picker-slot span.disabled {
    background-color: #fbfbfb;
    color: #cecece;
    pointer-events: none;
}