#my-logs {
  .my-logs-card-wrapper {
    color: var(--title-color);
    background-color: var(--cardBg1);
    border: 1px solid var(--borderGray);
    @include border-radius(15px);
    padding: 1.25rem;
    margin-bottom: 0.75rem;

    .mlc-header {
      margin-bottom: 0.75rem;
      align-items: baseline;
          margin-top: 13px;
      span.log-type {
        margin-left: auto;
        margin-top: -3px;
        background-color: #ff9234;
        color: white;
        padding: 8px;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        padding-top: 2px;
      }
    }

    .mlc-mail {
      background-color: var(--cardBg);
      @include border-radius(5px);
      padding: 0.3125rem 0.625rem;
      font-size: 0.9375rem;
      font-weight: 400;
      margin-bottom: 0.75rem;
      display: inline-block;
    }
    .mlc-buttons {
      .btn {
        border: none;
        // min-height: 35px;
        // min-width: auto;
        border: none;
        // flex: 1;
        margin-left: 0.625rem;
        margin-bottom: 0;
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
        min-width: auto;

        &:first-child {
          margin-left: 0;
        }
      }
      .secondary-btn:hover {
        box-shadow: none;
      }
    }
    .log-released-tag{
      position: absolute;
    top: 1px;
    right: 6px;
    padding: 2px;
    color: white;
    border-radius: 0px 14px 0px 11px;
    background-color: theme-color-level(success, -10);
      border-color: theme-color-level(success, -8);
      color:$success;
    font-size: 12px;
    padding-left: 8px;
    padding-right: 8px;
    }
  }

  .tb-my-logs-wrapper {
    .col-xl-4 {
      @media (min-width: 1280px) {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
      }
      @media (min-width: 1920px) {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
    }
  }
}
