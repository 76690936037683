#funeral-plans {
  .fp-section-wrapper {
    color: var(--title-color);
    margin-bottom: 1.875rem;

    .fps-inner {
      margin-bottom: 0.5rem;
    }

    .fps-header {
      font-weight: 700;
      border-bottom: 1px solid var(--borderGray);
      padding-bottom: 0.625rem;
      margin-bottom: 0.625rem;
    }

    p {
      color: var(--text-color);
      font-weight: 400 !important;
      margin-bottom: 1.125rem;
    }
    .invited-list {
      background-color: var(--cardBg1);
      border-radius: 8px;
      min-height: 57vh;
      border: solid 1px;
      border-color: var(--borderGray);
      margin-bottom: 14px;
      a {
        margin-bottom: 4px !important;
        display: block;
      }
      h6 {
        margin-bottom: 6px;
        padding: 10px;
        background-color: var(--cardBg) !important;
        border-radius: 12px 12px 0px 0px;
        border-bottom: solid 1px;
        border-color: var(--borderGray);
      }
      p {
        margin: 0 !important;
        font-weight: 500 !important;
        color: #535353 !important;
      }
      .recipient-list {
        padding-left: 11px;
        padding-right: 11px;
        ul {
          padding: 0;
          li {
            list-style: none;
            display: inline-block;
            margin-bottom: 7px;
            margin-right: 7px;
            span {
              background-color: var(--lightGray);
              border: solid 1px;
              border-color: var(--borderGray);
              color: $info;
              padding-left: 6px;
              padding-right: 6px;
              border-radius: 15px;
            }
          }
        }
      }
    }
  }
  .fps-left {
    flex: 1;
    font-weight: 500;
  }

  .fps-right {
    flex: 3;
    margin-left: 0.9375rem;
    color: var(--text-color);
  }

  .block-wrapper {
    position: relative;
    @include border-radius(5px);
    color: var(--text-color);
    padding: 0.425rem;
    cursor: pointer;
    transition: 0.3s;
  }
  .hide-block {
    position: absolute;
    right: 10px;
    top: 4px;
    z-index: 99999;
    display: none;
    span {
      border-radius: 3px;
      background-color: var(--cardBg1);
      padding: 5px 6px;
      margin-left: 10px;
      &:hover {
        background-color: white;
      }
      &:focus {
        background-color: $primary;
        color: $white;
      }
    }
    a {
      color: var(--title-color);
    }
  }
  .block-wrapper:hover {
    background-color: var(--hoverBg);
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;

    .hide-block {
      display: block;
    }
  }
}
.send-invitation.modal {
  .invited-list-modal {
    padding: 10px;
    border: solid 1px;
    border-color: #e0e2e4;
    border-radius: 6px;
    margin-top: 7px;
    margin-bottom: 8px;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        display: inline-block;
        margin-left: 4px;
        margin-bottom: 7px;
        span {
          background-color: var(--lightGray);
          border: solid 1px;
          border-color: var(--borderGray);
          color: $info;
          padding-left: 6px;
          padding-right: 6px;
          border-radius: 15px;
        }
      }
      .invitation-info p {
        width: 11%;
      }
    }
  }
}
