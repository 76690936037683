.card_scroll {
  min-height: calc(100vh - 160px);
  position: relative;
  overflow: auto;
  padding-bottom: 100px;
}

.ant-steps-label-vertical .ant-steps-item-content {
  width: 80px;
}

.ant-steps-label-vertical .ant-steps-item-tail {
  padding: 3.5px 0;
}

.ant-steps-label-vertical .anticon {
  display: none;
}

.create-will-radio {
  white-space: nowrap;
}

.ant-steps-item.ant-steps-item-finish
  .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: $primary;
}

.ant-steps-item.ant-steps-item-finish:nth-child(1) span.ant-steps-icon::after {
  content: ' 1';
}

.ant-steps-item.ant-steps-item-finish:nth-child(2) span.ant-steps-icon::after {
  content: ' 2';
}

.ant-steps-item.ant-steps-item-finish:nth-child(3) span.ant-steps-icon::after {
  content: ' 3';
}

.ant-steps-item.ant-steps-item-finish:nth-child(4) span.ant-steps-icon::after {
  content: ' 4';
}

.ant-steps-item.ant-steps-item-finish:nth-child(5) span.ant-steps-icon::after {
  content: ' 5';
}

.ant-steps-item.ant-steps-item-finish:nth-child(6) span.ant-steps-icon::after {
  content: ' 6';
}

.ant-steps-item.ant-steps-item-finish:nth-child(7) span.ant-steps-icon::after {
  content: ' 7';
}

.ant-steps-item.ant-steps-item-finish:nth-child(8) span.ant-steps-icon::after {
  content: ' 8';
}

.ant-steps-item .ant-steps-item-container {
  text-align: center;
  display: inline-block;
}

.ant-steps-item .ant-steps-item-container .ant-steps-item-icon {
  display: inline-block !important;
  margin: 0;
}

.ant-steps-item .ant-steps-item-container .ant-steps-item-title {
  padding-right: 0;
  font-weight: 300;
  font-size: 14px;
}

.ant-steps-item .ant-steps-item-container .ant-steps-item-title::after {
  top: -15px;
}

.ant-steps-item.ant-steps-item-active .ant-steps-item-title {
  color: orange !important;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #99a0ac;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: orange;
}

.ant-steps-item-process .ant-steps-item-icon {
  background: orange;
  border-color: orange;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: orange;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: orange;
}

.ant-steps-item-wait .ant-steps-item-icon {
  border-color: #99a0ac;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #99a0ac;
}

.steps-content .radio-inline {
  white-space: nowrap;
  margin-right: 20px;
}

.steps-content h3 span {
  color: orange;
}

.steps-action {
  position: absolute;
  left: 20px;
  right: 25px;
  bottom: 30px;
}

.steps-action button {
  min-width: 200px;
  height: auto;
}

.min_btn_wrp button {
  min-width: 220px;
  padding: 20px 15px !important;
  height: auto;
  color: #535353;
  background-color: #ebebeb;
  border: 1px solid #ebebeb;
  text-shadow: none;
  border-radius: 4px;
  border: none!important;
  display: block;
  text-align: center;
  cursor: pointer !important;
}

@media (max-width: 575.98px) {
  .min_btn_wrp button {
    min-width: auto;
  }
}

.min_btn_wrp button:hover {
  color: $primary-hover;
}

.min_btn_wrp button.ant-tabs-tab-active,
.min_btn_wrp button:focus,
.min_btn_wrp button.ant-tabs-tab-active:focus {
  color: $white;
  background-color: $primary;
  outline: none;
}

.min_btn_wrp .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.min_btn_wrp .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  display: none;
}

.min_btn_wrp .ant-tabs-top > .ant-tabs-nav::before,
.min_btn_wrp .ant-tabs-bottom > .ant-tabs-nav::before,
.min_btn_wrp .ant-tabs-top > div > .ant-tabs-nav::before,
.min_btn_wrp .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border: none;
}

.section_header {
  border-bottom: 1px solid #dee2e6;
}

.lawyer_btn_wrp .btn {
  min-width: 250px;
}

.ant-tabs-nav-wrap {
  display: flex;
  justify-content: center;
}

.collaspe_wrp .ant-collapse {
  border: none;
  background: transparent;
}

.collaspe_wrp .ant-collapse .ant-collapse-item {
  background: transparent;
  margin-bottom: 5px;
  border: 1px solid #e8e8e8;
}

.collaspe_wrp
  .ant-collapse
  .ant-collapse-item
  span.anticon.anticon-right.ant-collapse-arrow {
  border: 1px solid #c3c3c3;
  height: 25px;
  width: 25px;
  padding: 6px;
  border-radius: 50%;
}

.collaspe_wrp
  .ant-collapse
  .ant-collapse-item
  span.anticon.anticon-right.ant-collapse-arrow
  svg {
  color: #c3c3c3;
}

.agreement_wrp {
  border: 1px solid #bcbcbca0;
  padding: 40px;
}

.agreement_wrp h4 {
  margin-bottom: 0;
}

.agreement_wrp ol {
  margin: 0;
  padding: 0;
}

.agreement_wrp ol li p {
  font-weight: 300;
  font-size: 14px;
}

.agreement_wrp ol li p b {
  font-weight: 500;
}

.agreement_wrp ol li .appointment_sub {
  margin-top: 20px;
}

.agreement_wrp ol li .appointment_sub p {
  margin-bottom: 0;
}

.lawyer_list_item {
  box-shadow: 0px 3px 30px #0000000f;
  padding: 15px;
  border-radius: 8px;
}

.lawyer_profile_img {
  max-width: 200px;
  height: fit-content;
}

.character-limit span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

@media (max-width: 575.98px) {
  .beneficiaries_form .ant-select.form-control {
    margin-bottom: 16px;
    padding: 0;
  }
  .beneficiaries_form .table-row.table-data {
    padding: 20px 40px;
  }
  .steps-content .pt_40 {
    padding-top: 0;
  }
  .steps-content h3 {
    margin-top: 0;
  }
  .steps-action {
    left: 30px;
    right: 30px;
    display: flex;
  }
  .steps-action button {
    min-width: 48% !important;
    height: auto;
  }
  .steps-action button:first-child {
    margin-left: 0 !important;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    display: block !important;
  }
  button#rc-tabs-0-tab-2 {
    margin-top: 10px;
  }
  .min_btn_wrp .ant-tabs-content .mt_30 {
    margin-top: 1rem;
  }
  .min_btn_wrp button {
    width: 100%;
    margin-top: 10px;
  }
  .min_btn_wrp p.mt_30.pl_40.pr_40 {
    padding: 0 !important;
    margin-top: 0;
  }
}

@media (max-width: 767.98px) {
  .ant-steps-label-vertical {
    margin-bottom: 2rem;
  }
  .ant-steps-label-vertical .ant-steps-item-tail {
    padding: 0;
    margin-left: 20px;
    top: 9px;
  }
  .ant-steps-label-vertical .ant-steps-item-content {
    display: none;
  }
  .ant-steps-label-vertical .ant-steps-item-process .ant-steps-item-icon {
    background: orange;
  }
  .ant-steps-label-vertical
    .ant-steps-item-process
    .ant-steps-item-icon
    .ant-steps-icon {
    color: white;
    font-weight: 500;
  }
  .ant-steps-label-vertical .ant-steps-item-icon {
    width: 20px;
    height: 20px;
    border: none;
    font-size: 12px;
    line-height: 22px;
    background: #fdead0;
  }
  .ant-steps-label-vertical .ant-steps-item-icon .ant-steps-icon {
    color: orange;
  }
}

@media (max-width: 575.98px) {
  .ant-steps-label-vertical .ant-steps-item-title {
    font-size: 13px !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .ant-steps-label-vertical .ant-steps-item-title {
    font-size: 11px !important;
  }
  .ant-steps-label-vertical .ant-steps-item-content {
    width: 60px;
  }
}

// _::-webkit-full-page-media,
// _:future,
// :root .radio-inline {
//   position: relative;
// }

// _::-webkit-full-page-media,
// _:future,
// :root input[type='radio']:after {
//   position: absolute;
// }

// .create-will-radio .radio-inline input {
//   visibility: hidden;
// }

/*# sourceMappingURL=index.css.map */

// tab view styles
.summary-content {
  nav.nav.nav-tabs {
    // border: none;
    a.nav-item.nav-link {
      // border: none;
      // background-color: #efefef;
      // padding: 3px 10px;
      // border-radius: 5px;
      // margin: 9px 0px 9px 5px;
      // color: #203559;
        color: #51575e;
    }
    .nav-item.nav-link.active {
      // background-color: #fde9d5 !important;
      color: $primary;
    }
    a#switch-design-tab-preview {
      margin-left: auto;
    }
  }
}
