html,
body {
  font-size: 0.9375rem;
  font-family: $primary-font;
  color: var(--text-color);
  background-color: var(--bodyBg);
  min-height: 100%;
  height: 100%;
}
/**
 *  Typography
 *
 **/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $primary-font;
  color: var(--title-color);
  line-height: 1.2em !important;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
}
a {
  color: var(--info);
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 600;
}

h1,
.h1 {
  font-size: 2.25rem;
}
h2,
.h2 {
  font-size: 1.875rem;
}
h3,
.h3 {
  font-size: 1.5rem;
}
h4,
.h4 {
  font-size: 1.25rem;
}
h5,
.h5 {
  font-size: 1.125rem;
}
p {
  font-size: 0.9375rem;
  color: var(--text-color);
}
td,
th {
  border-radius: 0px;
}
.content_loader {
  svg {
    width: 100%;
  }
}
.errorMsg {
  color: $danger;
  font-size: 0.85rem;
  margin-top: 4px;
  display: block;
  font-weight: 400;
  margin-bottom: 10px;
}
.warningMsg {
  color: $warning;
  font-size: 0.85rem;
  margin-top: 4px;
  display: block;
}
.hide {
  @include media-breakpoint-up(lg) {
    display: none;
  }
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}
.hide.av-buttons-hide {
  display: none !important;
}
.full-width {
  width: 100%;
}
.fz-11 {
  font-size: 11px;
}
.fz-12 {
  font-size: 12px;
}
.fz-13 {
  font-size: 13px;
}
.fz-14 {
  font-size: 14px;
}
.fz-15 {
  font-size: 15px;
}
.fz-16 {
  font-size: 16px;
}
.fz-18 {
  font-size: 18px;
}
.fz-19 {
  font-size: 19px;
}
.fz-20 {
  font-size: 20px;
}

.fz-30 {
  font-size: 30px;
}
.fz-48 {
  font-size: 48px !important;
}
.fw100 {
  font-weight: 100;
}
.fw300 {
  font-weight: 300;
}
.fw400 {
  font-weight: 400 !important;
}
.fw500 {
  font-weight: 500;
}
.f700 {
  font-weight: 700;
}
.fsi {
  font-style: italic;
}
// Boarder Redius
.rounded-full {
  border-radius: 50%;
}
// Display felx setting
.df-sm {
  @include media-breakpoint-only(xs) {
    display: block !important;
  }
}
.d-flex-md {
  @include media-breakpoint-up(md) {
    display: flex !important;
  }
}
.d-block-md {
  @include media-breakpoint-down(md) {
    display: block !important;
  }
}
// Display none setting
.dh-xs {
  display: block;
  @include media-breakpoint-only(xs) {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .mob-mt-10 {
    margin-top: 10px;
  }
}
.dh-all {
  display: none;
}

.plr-25 {
  padding: 0 25px;
}

@include forSize(phone) {
  .plr-25 {
    padding: 0 15px;
  }
  .p-25 {
    padding: 15px;
  }
}

.table .text-right {
  text-align: right;
}
.table .text-left {
  text-align: left;
}
.table .text-center {
  text-align: center;
}

// border
.dropdown-divider {
  border-top: 1px solid var(--borderGray);
}
.border {
  border: solid 1px;
  border-color: var(--borderGray) !important;
}
.border-none {
  border: none !important;
}
.border-l {
  border-left: solid 1px;
  border-color: var(--borderGray);
}
.border-r {
  border-right: solid 1px;
  border-color: var(--borderGray);
}
.border-t {
  border-top: solid 1px;
  border-color: var(--borderGray);
}
.border-b {
  border-bottom: solid 1px;
  border-color: var(--borderGray);
}
.hide-mobile {
  display: block;
  @include media-breakpoint-down(md) {
    display: none !important;
  }
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.text-xs {
  font-size: 12px;
  opacity: 0.7;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.tags {
  border: solid 1px;
  border-color: var(--cardBg1);
  background-color: var(--borderGray);
  padding-left: 7px;
  padding-right: 10px;
  border-radius: 16px;
  display: inline-table;
  svg {
    height: 14px;
    width: auto;
    margin-top: -5px;
  }
  &.draft {
    background-color:var(--warning);
    color: $white;
    border-color:theme-color-level(warning, 1);
    svg {
      fill: $white;
    }
  }
  &.amend-draft {
    background-color:var(--warning);
    color: $white;
    border-color:theme-color-level(warning, 1);
    svg {
      fill: $white;
    }
  }
  &.finalized {
    background-color:var(--success);
    color: $white;
    border-color:theme-color-level(success, 1);
    svg {
      fill: $white;
    }
  }
  &.in-review {
    background-color:var(--info);
    color:$white;
    border-color:theme-color-level(info, 1);
    svg {
    fill:$white;
    }
  }
  &.notarized{
    background-color:var(--indigo);
    color: $white;
    border-color:theme-color-level(indigo-custom, 1);
    svg {
    fill: $white;
    }
  }
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}
// Icons default
.anticon-info-circle {
  font-size: 40px;
  color: $info;
}
span.anticon.anticon-warning {
  font-size: 18px;
  color: $warning;
}
.smallInput {
  .label {
    font-weight: 500;
    margin-bottom: 3px;
    width: auto;
    color: var(--title-color);
  }
  input,
  select,
  textarea {
    border-radius: 5px;
    border: 1px solid var(--borderGray);
    background-color: var(--inputsBg);
    padding: 12px 10px;
    width: 100%;
    font-weight: 400;
    font-size: 0.9rem;
    box-shadow: none;
    color: var(--inputPlaceholder);
    &::placeholder {
      color: var(--inputPlaceholder);
      font-weight: 300;
    }
    @include forSize(desktop-lg) {
    }
  }
  .passwordInputWrapper {
    position: relative;
    .passwordToggle {
      position: absolute;
      top: 12px;
      transform: translateY(-50%);
      right: 4px;
      padding: 6px;
      background: transparent;
    }
  }
}
// Button Styles ant and bootstrap

.btn {
  line-height: 1.5;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  text-shadow: none;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;
}
.ant-btn {
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-shadow: 0px 3px 2px rgba(150, 150, 150, 0.25);
}

.btn-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;
  &:hover {
    color: $white;
    background-color: $primary-hover;
    border-color: $primary;
  }
  &:focus {
    color: $white;
  }
  &:disabled {
    color: $white;
  }
}

.ant-btn-primary {
  color: $white;
  background-color: $primary;
  border-color: $primary;
  &:hover {
    color: $white;
    background-color: $primary-hover;
    border-color: $primary;
  }
  &:active {
    background-color: $primary-hover;
    border-color: $primary;
  }
  &:focus {
    background-color: $primary-hover;
    border-color: $primary;
  }
}
// Start Soft Button Styles
.btn-primary-soft {
  color: $primary;
  background-color: theme-color-level(primary, -10);
  border-color: theme-color-level(primary, -8);
  &:hover {
    background-color: theme-color-level(primary, -8);
    color: $primary;
  }
  &:active {
    background-color: theme-color-level(primary, -8);
    color: $primary;
  }
  &:focus {
    box-shadow: 0 0 0 $input-focus-width theme-color-level(primary, -10);
  }
}
.btn-secondary-soft {
  color: $secondary;
  background-color: theme-color-level(secondary, -10);
  border-color: theme-color-level(secondary, -8);
  &:hover {
    background-color: theme-color-level(secondary, -8);
    color: $secondary;
  }
  &:active {
    background-color: theme-color-level(secondary, -8);
    color: $secondary;
  }
  &:focus {
    color: $secondary;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(secondary, -10);
  }
}
.btn-success-soft {
  color: $success;
  background-color: theme-color-level(success, -10);
  border-color: theme-color-level(success, -8);
  &:hover {
    background-color: theme-color-level(success, -8);
    color: $success;
  }
  &:active {
    background-color: theme-color-level(success, -8);
    color: $success;
  }
  &:focus {
    color: $success;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(success, -10);
  }
}
.btn-warning-soft {
  color: $warning;
  background-color: theme-color-level(warning, -10);
  border-color: theme-color-level(warning, -8);
  &:hover {
    background-color: theme-color-level(warning, -8);
    color: $warning;
  }
  &:active {
    background-color: theme-color-level(warning, -8);
    color: $warning;
  }
  &:focus {
    color: $warning;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(warning, -10);
  }
}
.btn-danger-soft {
  color: $danger;
  background-color: theme-color-level(danger, -10);
  border-color: theme-color-level(danger, -8);
  &:hover {
    background-color: theme-color-level(danger, -8);
    color: $danger;
  }
  &:active {
    background-color: theme-color-level(danger, -8);
    color: $danger;
  }
  &:focus {
    color: $danger;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(danger, -10);
  }
}
.btn-info-soft {
  color: $info;
  background-color: theme-color-level(info, -10);
  border-color: theme-color-level(info, -8);
  &:hover {
    background-color: theme-color-level(info, -8);
    color: $info;
  }
  &:active {
    background-color: theme-color-level(info, -8);
    color: $info;
  }
  &:focus {
    color: $info;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(info, -10);
  }
}
.btn-light-soft {
  color: gray("900");
  background-color: theme-color-level(light, -1);
  border-color: theme-color-level(dark, -10);
  &:hover {
    background-color: theme-color-level(dark, -10);
    color: $dark;
  }
  &:active {
    background-color: theme-color-level(light, -8);
    color: gray("100");
  }
  &:focus {
    color: gray("900");
    box-shadow: 0 0 0 $input-focus-width theme-color-level(dark, -10);
  }
}
// End Soft Button Styles

.btn-outline-primary {
  &:hover {
    background-color: theme-color-level(primary, -10);
    color: $primary;
    border-color: theme-color-level(primary, -6);
  }
  &:active {
    color: $primary !important;
    background-color: theme-color-level(primary, -10) !important;
  }
  &:focus {
    color: $primary;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(primary, -10);
  }
}
.btn-outline-secondary {
  &:hover {
    background-color: theme-color-level(secondary, -10);
    color: $secondary;
  }
  &:active {
    color: $secondary !important;
    background-color: theme-color-level(secondary, -10) !important;
  }
  &:focus {
    color: $secondary;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(secondary, -10);
  }
}
.btn-outline-success {
  &:hover {
    background-color: theme-color-level(success, -10);
    color: $success;
  }
  &:active {
    color: $success !important;
    background-color: theme-color-level(success, -10) !important;
  }
  &:focus {
    color: $success;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(success, -10);
  }
}
.btn-outline-warning {
  &:hover {
    background-color: theme-color-level(warning, -10);
    color: $warning;
  }
  &:active {
    color: $warning !important;
    background-color: theme-color-level(warning, -10) !important;
  }
  &:focus {
    color: $warning;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(warning, -10);
  }
}
.btn-outline-danger {
  &:hover {
    background-color: theme-color-level(danger, -10);
    color: $danger;
  }
  &:active {
    color: $danger !important;
    background-color: theme-color-level(danger, -10) !important;
  }
  &:focus {
    color: $danger;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(danger, -10);
  }
}
.btn-outline-info {
  &:hover {
    background-color: theme-color-level(info, -10);
    color: $info;
  }
  &:active {
    color: $info !important;
    background-color: theme-color-level(info, -10) !important;
  }
  &:focus {
    color: $info;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(info, -10);
  }
}
.btn-outline-light {
  color: $dark !important;
  border-color: theme-color-level(dark, -10);
  &:hover {
    background-color: theme-color-level(dark, -10);
    color: $dark !important;
  }
  &:active {
    color: $dark !important;
    background-color: theme-color-level(dark, -10) !important;
  }
  &:focus {
    color: $dark;
    box-shadow: 0 0 0 $input-focus-width theme-color-level(dark, -10);
  }
}
// .btn-outline-primary {
//   color: $primary;
//   border-color: $primary;
//   &:hover {
//     color: $primary;
//     border-color: $primary-hover;
//   }
// }
.ant-btn-background-ghost.ant-btn-primary {
  color: $primary;
  background: transparent;
  border-color: $primary;
  text-shadow: none;
  &:hover {
    color: $primary-hover;
    border-color: $primary-hover;
    background: theme-color-level(primary, -10) !important;
  }
  &:active {
    color: $primary-hover;
    border-color: $primary-hover;
    background: theme-color-level(primary, -10) !important;
  }
  &:focus {
    color: $primary-hover;
    border-color: $primary-hover;
  }
}
button svg {
  font-size: 17px;
  margin-top: -4px;
}

.action-items {
  a,
  button {
    background: theme-color-level(primary, -10);
    height: 30px;
    width: 30px;
    border-radius: 4px;
    margin-right: 5px;
    display: grid;
    place-items: center;
    transition: 0.3s;
    &:hover {
      background: theme-color-level(primary, -8);
      box-shadow: 0 4px 15px -6px rgba(0, 0, 0, 0.2);
    }
    border: none;
    i {
      color: $primary;
    }
  }
  button:disabled {
    background-color: #ececec !important;
    cursor: not-allowed;
    i {
      color: #cacaca;
    }
    &:hover {
      background-color: #ececec;
      box-shadow: none;
    }
  }
}
.acbut {
  background-color: theme-color-level(secondary, -11);
  color: $secondary;
  border-color: theme-color-level(secondary, -8) !important;
  padding: 8px;
  &:hover {
    background-color: theme-color-level(secondary, -10);
    color: $secondary !important;
    border-color: theme-color-level(secondary, -8);
  }
  &:focus {
    background-color: theme-color-level(primary, -10);
  }
}

.primary-switch {
  width: 35px;
  height: 17px;
  border-radius: 8.5px;
  background: #fff;
  position: relative;
  cursor: pointer;
}
.primary-switch input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.primary-switch input + label {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.primary-switch input + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 8.5px;
  cursor: pointer;
  border: solid 1px;
  border-color: #dee2e6;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
.primary-switch input + label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ffffff;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: solid 1px;
  border-color: #dee2e6;
}
.primary-switch input:checked + label:after {
  left: 19px;
}
.primary-switch input:checked + label:before {
  background: $primary;
}

.tabLine {
  // box-shadow: 0 18px 35px -8px rgba(161, 176, 205, 0.25);
  border-bottom: solid 1px;
  border-color: var(--borderGray);
  .nav-tabs {
    border: none;
  }
  .nav.nav-tabs li {
    margin-bottom: 0px;
    position: relative;
  }
  .nav-link {
    border: none;
    color: var(--title-color);
    cursor: pointer;
  }
  .nav-link.active {
    border: none;
    color: $primary;
    background: none;
  }
  .nav-tabs .nav-link.active:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $primary;
    left: 0;
    bottom: -1.4px;
    transition: 0.5s;
  }
  .nav-tabs .nav-link:after {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: $primary;
    left: 0;
    bottom: -1.4px;
    transition: 0.5s;
  }
}
.default-tab {
  ul {
    li {
      padding: 0;
      a .nav-link {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
    li .nav-link.active {
      font-weight: 500;
    }
  }
  .nav-tabs .nav-link {
    @include media-breakpoint-down(md) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
.modal_btn_wrp {
  .btn {
    min-width: 100px;
    &:last-child {
      margin-left: 10px;
    }
  }
}
.checkbox {
  display: block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  user-select: none;
  font-weight: 300;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: $primary !important;
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 4px;
    border: 2px solid $primary;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 0.5px;
      width: 5px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
  &:hover input ~ .checkmark {
    background-color: theme-color-level(primary, -10);
  }
}

/* Ishara - Radio Buttons - Start */
.radio-inline {
  display: inline-flex;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }
}
.radio-inline .regular-radio {
  opacity: 0;
  width: auto;
  margin-left: -12px;
}
.radio-inline label {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  line-height: 25px;
  margin-bottom: 0;
  cursor: pointer;
}
.radio-inline label::before,
.radio-inline label::after {
  position: absolute;
  content: "";
  display: inline-block;
}
.radio-inline label::before {
  height: 18px;
  width: 18px;
  @include border-radius(9px);
  border: 1px solid $primary;
  background-color: var(--inputsBg);
  left: 0px;
  top: 3px;
}
.radio-inline label::after {
  height: 18px;
  width: 18px;
  @include border-radius(9px);
  left: 0px;
  top: 3px;
  background-color: var(--cardBg1);
  border: 5px solid $primary;
}
.radio-inline .regular-radio + label::after {
  content: none;
}
.radio-inline .regular-radio:checked + label::after {
  content: "";
}

/* Ishara - Radio Buttons - End */

.dividerWithText {
  font-size: 0.9375rem;
  color: #7c7c7c;
  display: flex;
  .line {
    flex: 1;
    position: relative;
    &:after {
      position: absolute;
      left: 0;
      top: 50%;
      height: 1px;
      background: #dedede;
      content: "";
      width: 100%;
      display: block;
    }
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
}
.add-btn-wrp {
  position: absolute;
  right: 1rem;
  top: -5.3rem;
}
@include forSize(tablet-landscape) {
  .add-btn-wrp {
    right: 0;
    top: -7rem;
  }
}
@include forSize(phone) {
  .tabLine {
    .nav.nav-tabs {
      li {
        margin-bottom: 0px;
        position: relative;
        width: 50%;
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 10px !important;
      }
    }
  }
  .cardDefault.full-page .empty h3,
  .cardDefault.play-meida .empty h3 {
    font-size: 16px;
  }
  .add-btn-wrp {
    text-align: center;
    position: relative;
    top: 0;
    right: 0;
  }
  .section-wrp {
    .cardTitle.d-flex.justify-content-between.align-items-center.full-width {
      display: block !important;
      .add-invite-btn {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
.widgetWrapper {
  // padding: 10px;
  // padding-top: 0;
  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}
.form-control {
  height: auto;
  &:focus{
    border: 1px solid var(--borderGray) !important;
    background-color: var(--inputsBg) !important;
  }
  &:disabled{
    border: 1px solid var(--borderGray) !important;
    background-color: var(--inputsBg) !important;
    cursor: not-allowed;
  }
}

// card scroll bar
.cardFullView::-webkit-scrollbar {
  visibility: hidden;
  display: none;
}
.cardFullView {
  height: calc(100vh - 86px);
  overflow-y: auto;
  margin-bottom: 0;
  padding: 25px;
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}
// card scroll bar
.wizard::-webkit-scrollbar-thumb {
  background-color: var(--cardBg1);
  border-radius: 0px 10px 10px 0px;
}
.wizard::-webkit-scrollbar-track {
  background: var(--cardBg);
  border-radius: 0px 10px 10px 0px;
}
.wizard {
  height: calc(100vh - 98px);
  margin-bottom: 0;
  padding: 25px;
  padding-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  @include media-breakpoint-up(md) {
    overflow-y: scroll;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: var(--scroll-bar);
    }
    &::-webkit-scrollbar-track {
      background-color: var(--cardBg);
    }
  }
  @include media-breakpoint-only(xl) {
    height: calc(100vh - 98px);
  }
  @include forSize(desktop-xl-up) {
    height: auto;
  }
  @include media-breakpoint-down(md) {
    padding: 10px;
  }
}
// Scroll bar Styles
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--cardBg);
  //background: red;
  
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--scroll-bar);
  border-radius: 20px;
  //  background: blue;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar);
}
// Custom Table
.toolbar {
  margin-right: 10px;
  .custom-option {
    color: #5e676f;
    margin: 4px;
    padding: 6px;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.options {
  padding: 15px 0 25px 0;
}
.options .input-group-text {
  background-color: white;
  color: #5e676f;
}

.options input {
  border-left: navajowhite;
}
.custom-option {
  background-color: white;
  border-color: #ced4da;
  padding: 5px !important;
}
.fixed-table-toolbar .custom-checkbox {
  margin-top: 5px;
}
.search-box {
  position: relative;
}

.search-box .input-group-text {
  position: absolute;
  right: 0;
  z-index: 9;
  padding: 10.1px;
  border-right: 0;
  border-bottom: navajowhite;
  border: none;
  top: 1px;
  background: none;
}

.search-box input {
  padding-right: 46px;
}
.search-box input {
  border: solid 1px;
  border-radius: 4px !important;
  border: 1px solid var(--borderGray);
  background-color: var(--inputsBg);
  &::placeholder {
    color: var(--inputPlaceholder);
    font-weight: 300;
  }
}

.table-custom {
  min-height: 38vh;
  margin-bottom: 10px;
  .table-header {
    border-bottom: solid 2px;
    padding-bottom: 5px;
    font-weight: 500;
    border-color: var(--borderGray);
    @include forSize(tablet-landscape) {
      display: none;
    }
    span {
      color: $danger;
      font-weight: 200;
    }
  }
  .table-data {
    @include forSize(tablet-lg) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .invite-text {
      @include forSize(tablet-lg) {
        margin-bottom: 10px;
      }
    }
  }
  .custom-table-responsive {
    .table-row {
      padding: 12px;
      border: 1px solid var(--borderGray);
      border-top: none;
      padding-left: 0px;
      padding-right: 0px;
      @include forSize(phone) {
        margin-bottom: 30px;
      }
      @include forSize(ipad-landscape) {
        margin-bottom: 0px;
        padding-left: 30px;
      }
      @include forSize(ipad-pro-portrait) {
        margin-bottom: 0;
        padding-left: 34px;
      }
    }
  }
  .table-row {
    border-bottom: solid 1px;
    border-color: var(--borderGray);
    padding-top: 12px;
    @include forSize(phone) {
      margin-bottom: 17px !important;
    }
    @include forSize(tablet-landscape) {
      padding: 16px;
      border: none !important;
      padding: 0px;
      margin-bottom: 25px;

      /* Ishara - Commented */
      // [class*='col'] {
      //   padding: 0;
      // }

      button {
        @include forSize(tablet-landscape) {
          margin: 0;
        }
        @include forSize(phone-landscape) {
          margin: 0;
        }
        @include forSize(phone) {
          margin: 6px;
        }
      }
    }
    .edit-option button {
      width: 100%;
      margin: 0;
      margin-bottom: 5px;
    }
  }
  .option {
    // display: -webkit-box;
    display: flex;
    button {
      display: block;
      margin-left: auto;
      margin-right: auto;
      @include forSize(tablet-landscape) {
        display: flex;
        padding: 0;
        margin-top: 18px;
        margin-bottom: 10px;
        padding-left: 6px;
        padding: 10px;
        margin-top: 21px;
        margin-left: initial;
        margin-right: initial;
        margin-right: 12px;
      }
    }
  }
  .table-data {
    @include forSize(tablet-landscape) {
      margin: -1px;
      border: none !important;
      box-shadow: 0 0 6px 1px #0000001a;
      border-radius: 4px;
      margin-bottom: 14px;
      width: 100%;
      .email {
        color: $primary;
      }
    }
  }
  label {
    display: none;
    @include forSize(tablet-landscape) {
      display: block !important;
    }
  }
  button {
    margin-left: 3px;
    margin-right: 3px;
  }
  input,
  textarea {
    @include forSize(tablet-landscape) {
      margin-bottom: 5px;
    }
    @include forSize(phone) {
      margin-bottom: 5px;
    }
  }
  span.errorMsg {
    @include forSize(phone) {
      margin-bottom: 10px;
    }
  }
}
.table-row.input-row {
  @include forSize(tablet-landscape) {
    padding: 0;
    margin-bottom: 15px;
  }
  button {
    padding: 7px !important;
    margin: 0;
  }
}
.cardDefault {
  &.invite-wrp {
    &.section-wrp {
      padding-bottom: 30px;
      .table {
        tr {
          th {
            vertical-align: middle;
            border-bottom: 0;
            &:nth-child(1) {
              width: 5%;
            }
            &:nth-child(2) {
              width: 20%;
            }
            &:nth-child(3) {
              width: 20%;
            }
            &:nth-child(4) {
              width: 45%;
            }
            &:nth-child(5) {
              width: 10%;
              text-align: center;
            }
          }
          td {
            vertical-align: middle;
            white-space: nowrap;
            &:nth-child(5) {
              text-align: center;
            }
            span.email {
              background-color: #ffffff;
              border: 1px solid #ececec;
              padding: 3px 10px;
              border-radius: 5px;
              font-size: 14px;
              box-shadow: 0 4px 5px -6px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }
}
.checkbox-column .custom-control {
  display: table-cell;
}
.text-limit1 {
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-height: 1.4rem;
  vertical-align: middle;
  display: -webkit-inline-box;
  @include forSize(phone) {
    -webkit-line-clamp: 2;
  }
  @include forSize(tablet-portrait) {
    -webkit-line-clamp: 2;
  }
}

.text-limit2 {
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4rem;
  vertical-align: middle;
  display: -webkit-inline-box;
  @include forSize(tablet-portrait) {
    -webkit-line-clamp: 2;
  }
  @include forSize(phone) {
    -webkit-line-clamp: 2;
  }
}
.text-limit3 {
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.4rem;
  vertical-align: middle;
  display: -webkit-inline-box;
  @include forSize(phone) {
    -webkit-line-clamp: 6;
  }
  @include forSize(tablet-portrait) {
    -webkit-line-clamp: 6;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .table-data [class*="col"] {
    margin-bottom: 10px;
  }

  .table-data [class*="col"] {
    padding: 0;
    margin-bottom: 9px;
  }
}
.empty {
  text-align: center;
  width: 100%;
  min-height: calc(100vh - 47rem);
  display: grid;
  place-items: center;
}
.ant-popover-inner-content {
  word-break: break-word;
  max-width: 400px;
}

.required {
  color: red;
  padding-left: 3px;
}
.content_loader {
  width: 100% !important;
}
// separator
.separator {
  border-bottom: solid 1px;
  border-color: var(--borderGray);
}
