.ml-widget {
  // height: calc(100% - 30px);
  text-align: center;

  .ant-avatar {
    margin-bottom: 0.625rem;
    border: 2px solid var(--cardBg1);
  }

  .lawyer-name {
    color: var(--title-color);
    font-size: 1rem;
    font-weight: 700;
    // margin-bottom: 0.3125rem;
  }

  .lawyer-designation {
    color: var(--text-color);
    font-size: 0.875rem;
    font-weight: 500;
  }
}
