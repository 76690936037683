.write_a_story {
  display: contents;
  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 355px);
    img {
      margin-bottom: 10px;
    }
  }
}
