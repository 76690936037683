// margin top
.mt-1 {
  margin-top: 1px;
}
.mt-2 {
  margin-top: 2px;
}
.mt-3 {
  margin-top: 3px;
}
.mt-4 {
  margin-top: 4px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-6 {
  margin-top: 6px;
}
.mt-7 {
  margin-top: 7px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-9 {
  margin-top: 9px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-11 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 10px;
}
.mt-13 {
  margin-top: 10px;
}
.mt-14 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-150 {
  margin-top: 150px;
}
// margin-left
.ml-a {
  margin-left: auto !important;
}
.ml-i {
  margin-left: inherit !important;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-50 {
  margin-left: 50px;
}
// margin-right
.mr-a {
  margin-right: auto !important;
}
.mr-i {
  margin-right: inherit !important;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mr-15 {
  margin-right: 15px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-30 {
  margin-right: 30px;
}
.mr-40 {
  margin-right: 40px;
}
.mr-50 {
  margin-right: 50px;
}
// margin-bottom
.mb-0 {
  margin-bottom: 0px;
}
.mb-0-i {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
// padding-all
.p-10 {
  padding: 10px;
}
.p-15 {
  padding: 15px;
}
.p-20 {
  padding: 20px;
}
.p-25 {
  padding: 25px;
}
.p-30 {
  padding: 30px;
}
.p-35 {
  padding: 35px;
}
.p-40 {
  padding: 40px;
}
// padding-top
.pt-0 {
  padding-top: 0px;
}
.pt-1 {
  padding-top: 0px;
}
.pt-1 {
  padding-top: 1px;
}
.pt-2 {
  padding-top: 2px !important;
}
.pt-3 {
  padding-top: 3px;
}
.pt-4 {
  padding-top: 4px;
}
.pt-5 {
  padding-top: 5px;
}
.pt-6 {
  padding-top: 6px;
}
.pt-7 {
  padding-top: 7px;
}
.pt-8 {
  padding-top: 8px;
}
.pt-9 {
  padding-top: 9px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-11 {
  padding-top: 11px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-120 {
  padding-top: 120px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-170 {
  padding-top: 170px;
}
// padding-bottom
.pb-0 {
  padding-bottom: 0px;
}
.mb-0-i {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.pb-11 {
  padding-bottom: 11px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-150 {
  padding-bottom: 150px;
}
// padding-Right
.pr-0 {
  padding-right: 0px;
}
.pr-1 {
  padding-right: 1px;
}
.pr-2 {
  padding-right: 2px;
}
.pr-3 {
  padding-right: 3px;
}
.pr-4 {
  padding-right: 4px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-6 {
  padding-right: 6px;
}
.pr-7 {
  padding-right: 7px;
}
.pr-8 {
  padding-right: 8px;
}
.pr-9 {
  padding-right: 9px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-11 {
  padding-right: 11px;
}
.pr-12 {
  padding-right: 12px;
}
.pr-13 {
  padding-right: 13px;
}
.pr-14 {
  padding-right: 14px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 25px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}
.pr-40 {
  padding-right: 40px;
}
.pr-45 {
  padding-right: 45px;
}
.pr-90 {
  padding-right: 90px;
}
// padding-Left
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 25px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}
.pl-40 {
  padding-left: 40px;
}
.pl-45 {
  padding-left: 45px;
}
.pl-90 {
  padding-left: 90px;
}

.m-0 {
  margin: 0px;
}
// Padding
.p_0 {
  padding: 0 !important;
}
.p_10 {
  padding: 0.625rem !important;
}
.p_15 {
  padding: 0.9375rem !important;
}
.p_20 {
  padding: 1.25rem !important;
}
.p_24 {
  padding: 1.5rem !important;
}
.p_40 {
  padding: 2.5rem !important;
}
.pt {
  &_0 {
    padding-top: 0 !important;
  }
  &_5 {
    padding-top: 0.3125rem;
  }
  &_10 {
    padding-top: 0.625rem;
  }
  &_15 {
    padding-top: 0.9375rem;
  }
  &_20 {
    padding-top: 1.25rem;
  }
  &_25 {
    padding-top: 1.5625rem;
  }
  &_30 {
    padding-top: 1.875rem;
  }
  &_35 {
    padding-top: 2.1875rem;
  }
  &_40 {
    padding-top: 2.5rem;
  }
}

.pb {
  &_0 {
    padding-bottom: 0 !important;
  }
  &_5 {
    padding-bottom: 0.3125rem !important;
  }
  &_8 {
    padding-bottom: 0.5rem !important;
  }
  &_10 {
    padding-bottom: 0.625rem !important;
  }
  &_15 {
    padding-bottom: 0.9375rem !important;
  }
  &_20 {
    padding-bottom: 1.25rem !important;
  }
  &_25 {
    padding-bottom: 1.5625rem !important;
  }
  &_30 {
    padding-bottom: 1.875rem;
  }
  &_35 {
    padding-bottom: 2.1875rem;
  }
  &_40 {
    padding-bottom: 2.5rem;
  }
}

.pl {
  &_0 {
    padding-left: 0 !important;
  }
  &_5 {
    padding-left: 0.3125rem !important;
  }
  &_10 {
    padding-left: 0.625rem !important;
  }
  &_15 {
    padding-left: 0.9375rem !important;
  }
  &_20 {
    padding-left: 1.25rem !important;
  }
  &_24 {
    padding-left: 1.5rem !important;
  }
  &_25 {
    padding-left: 1.5625rem !important;
  }
  &_30 {
    padding-left: 1.875rem !important;
  }
  &_35 {
    padding-left: 2.1875rem !important;
  }
  &_40 {
    padding-left: 2.5rem !important;
  }
  &_45 {
    padding-left: 2.8125rem !important;
  }
  &_50 {
    padding-left: 3.125rem !important;
  }
}

.pr {
  &_0 {
    padding-right: 0 !important;
  }
  &_5 {
    padding-right: 0.3125rem !important;
  }
  &_10 {
    padding-right: 0.625rem !important;
  }
  &_15 {
    padding-right: 0.9375rem !important;
  }
  &_20 {
    padding-right: 1.25rem !important;
  }
  &_24 {
    padding-right: 1.5rem !important;
  }
  &_25 {
    padding-right: 1.5625rem !important;
  }
  &_30 {
    padding-right: 1.875rem !important;
  }
  &_35 {
    padding-right: 2.1875rem !important;
  }
  &_40 {
    padding-right: 2.5rem !important;
  }
}

// Margin
.m_0 {
  margin: 0 !important;
}
.m_5 {
  margin: 0.313rem !important;
}
.m_10 {
  margin: 0.625rem !important;
}
.m_15 {
  margin: 0.938rem !important;
}
.m_20 {
  margin: 1.25rem !important;
}
.m_25 {
  margin: 1.563rem !important;
}
.mt {
  &_0 {
    margin-top: 0 !important;
  }
  &_5 {
    margin-top: 0.3125rem;
  }
  &_10 {
    margin-top: 0.625rem;
  }
  &_15 {
    margin-top: 0.9375rem;
  }
  &_20 {
    margin-top: 1.25rem;
  }
  &_25 {
    margin-top: 1.5625rem;
  }
  &_30 {
    margin-top: 1.875rem;
  }
  &_35 {
    margin-top: 2.1875rem;
  }
  &_40 {
    margin-top: 2.5rem;
  }
}

.mb {
  &_0 {
    margin-bottom: 0 !important;
  }
  &_5 {
    margin-bottom: 0.3125rem;
  }
  &_10 {
    margin-bottom: 0.625rem;
  }
  &_15 {
    margin-bottom: 0.9375rem;
  }
  &_20 {
    margin-bottom: 1.25rem;
  }
  &_25 {
    margin-bottom: 1.5625rem;
  }
  &_30 {
    margin-bottom: 1.875rem;
  }
  &_35 {
    margin-bottom: 2.1875rem;
  }
  &_40 {
    margin-bottom: 2.5rem;
  }
  &_90 {
    margin-bottom: 5.625rem !important;
  }
}

.ml {
  &_0 {
    margin-bottom: 0;
  }
  &_5 {
    margin-left: 0.3125rem;
  }
  &_10 {
    margin-left: 0.625rem;
  }
  &_15 {
    margin-left: 0.9375rem;
  }
  &_18 {
    margin-left: 1.125rem;
  }
  &_20 {
    margin-left: 1.25rem;
  }
  &_25 {
    margin-left: 1.5625rem;
  }
  &_30 {
    margin-left: 1.875rem;
  }
  &_35 {
    margin-left: 2.1875rem;
  }
  &_40 {
    margin-left: 2.5rem;
  }
}

.mr {
  &_0 {
    margin-bottom: 0;
  }
  &_5 {
    margin-right: 0.3125rem;
  }
  &_10 {
    margin-right: 0.625rem;
  }
  &_15 {
    margin-right: 0.9375rem;
  }
  &_20 {
    margin-right: 1.25rem;
  }
  &_25 {
    margin-right: 1.5625rem;
  }
  &_30 {
    margin-right: 1.875rem;
  }
  &_35 {
    margin-right: 2.1875rem;
  }
  &_40 {
    margin-right: 2.5rem;
  }
}

@media (max-width: 575.98px) {
  .mob_mt {
    &_5 {
      margin-top: 0.3125rem;
    }
    &_10 {
      margin-top: 0.625rem;
    }
    &_15 {
      margin-top: 0.9375rem;
    }
    &_20 {
      margin-top: 1.25rem;
    }
    &_25 {
      margin-top: 1.5625rem;
    }
    &_30 {
      margin-top: 1.875rem;
    }
  }

  .mb-5 {
    margin-bottom: 1rem !important;
  }
  .mob_mb {
    &_5 {
      margin-bottom: 0.3125rem;
    }
    &_10 {
      margin-bottom: 0.625rem;
    }
    &_15 {
      margin-bottom: 0.9375rem;
    }
    &_20 {
      margin-bottom: 1.25rem;
    }
    &_25 {
      margin-bottom: 1.5625rem;
    }
    &_30 {
      margin-bottom: 1.875rem;
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .mb-5 {
    margin-bottom: 1rem !important;
  }
}
