.coming-soon-bg {
  background-image: url("../../../../assets/images/cs-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;
}
.coming-soon-wrapper {
  text-align: center;
  padding: 2.125rem 0;
  .title {
    text-align: center;
    padding: 1.125rem 0;
    position: absolute;
    z-index: 2;
    top: 30%;
    width: 100%;
  }

  .nd-title {
    font-size: 2.1875rem;
    font-weight: 700;
    color: $primary;
  }

  .nd-description {
    font-size: 0.9375rem;
  }
}

.gradient-border {
  --border-width: 0px;
  border-radius: 12px;
  backdrop-filter: blur(0.2rem);
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
60deg
, #ffffff, #ffffff, #e0e0e0, #aec5fd, #b9def7, #ffffff, #ffffff, #ffffff);
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: 12px;
    backdrop-filter: blur(0.3rem);
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    animation: moveGradient 12s alternate infinite;
    z-index: 1;
    opacity: 0.7;
    -webkit-animation: moveGradient 12s alternate infinite;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
.coming-soon-wrapper {
  text-align: center;
  padding: 1.125rem 0;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  
}
}
