.modal {
  &-open {
    display: block !important;
    padding-left: 0px;
    opacity: 1;
    overflow-y: auto !important;
  }
  &-default-titile {
    color: #2f3240;
    font-size: 20px;
    font-family: Rubik Medium;
  }
  &-default-header {
    height: 36px;
    margin-top: 10px;
    padding: 0 15px;
    border-bottom: solid 1px;
    border-color: #eaeaea;
    padding-bottom: 5px;
  }
  &-default-footer {
    border: 0px;
    padding: 0px 15px;
    padding-bottom: 15px;
  }
  &-dialog {
    z-index: 99999;
    border-radius: 13px;
  }
  &-content {
    border-radius: 5px;
    background-color: #f7f7f7;
  }
  &-close {
    transform: scale(0);
    transform-origin: 0% 0;
    opacity: 0;
  }

  &-center-heade {
    background: bottom;
    background: linear-gradient(146deg, #29273f 8%, #030309 100%);
    border-radius: 11px 11px 0px 0px;
    box-sizing: border-box;
    padding: 40px;
  }
  &-logo {
    width: 30%;
  }
  &-on-close {
    cursor: pointer;
  }
  &-form-group {
    padding: 20px 40px;
  }
  &-space-top-10 {
    margin-top: 10vh;
  }
  &-space-top-20 {
    margin-top: 10vh;
  }
  .modal-body{
    h5{
      margin-bottom: 5px;
      line-height: 1.8rem !important;
    }
  }
}
.modal-footer {
  border-top: solid 1px;
  border-color: #d6d6d6a1;
  padding-bottom: 14px;
}
.modal-header {
  padding: 0.6rem 1rem;
  .close{
        /* padding: 1rem 1rem; */
        margin: -1rem -1rem -1rem auto;
        margin-left: auto;
        margin: 0;
        padding: 7px;
        background-color: #f7f7f7;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        &:hover{
          background-color: var(--hoverBg)
        }
  }
}
@media (min-width: 992px) {
  .modal-md {
    max-width: 600px;
  }
}
button.close:focus {
  outline: none;
}
.confirmation-mod{
  background-color: var(--cardBg);
  border-radius: 4px;
  .btn{
    width: 47%;
    margin-left: 3px;
    margin-right: 3px;
  }
}
.modal-scrollable .modal-body {
  height: 55vh;
  overflow-y: scroll;
}
.modal-backdrop.show {
  opacity: 0.7;
  backdrop-filter: blur(0.4rem);
}
.modal-content {
  border-radius: 5px;
  /* background-color: #f7f7f7; */
  background-color: var(--cardBg);
  border: solid 1px var(--borderGray);
  .modal-header{
    border-bottom-color: var(--borderGray);
    color: var(--title-color);
  }
}