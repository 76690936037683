.pw-left-wrapper {
  background-color: var(--cardBg);
  @include border-radius(8px);
  .user-list {
    height: 66vh;
    padding-bottom: 1.25rem;
    overflow-y: auto;
    overflow-x: hidden;
    @include media-breakpoint-down(sm) {
      height: 35vh;
    }
  }
  .avatar-row-wrapper {
    margin-bottom: 0;
    width: 100%;
    align-items: baseline;
    .avata-img {
      position: relative;
      span.ant-avatar.joint {
        position: absolute;
        right: 2px;
        top: 22px;
        box-shadow: 0 0 14px 5px #0000004f;
        @include media-breakpoint-down(sm) {
          top: 29px;
          width: 30px !important;
          height: 30px !important;
          font-size: 17px !important;
        }
      }
    }
    &:hover {
      p.time-update {
        visibility: inherit !important;
      }
    }
  }

  .avatar-bt-bg {
    padding: 5px;
    padding: 5px;
    border-radius: 0px 8px 6px 0px;
    margin-right: 7px;
    &:hover {
      background-color: var(--hoverBg);
    }
    &.active {
      background-color: var(--activeBg)
    }
  }

  .avatar-bt-bg:hover,
  .active {
    cursor: pointer;
  }

  .arw-right {
    flex: 1;
    .info {
      margin-top: -5px;
      p {
        margin: 0;
      }
      p.text-xs.time-update {
        margin-left: auto;
        visibility: hidden;
        margin-right: 11px;
      }
    }
    .user-name {
      position: relative;
      h6 {
        display: inline-block;
        max-width: 64%;
        height: 1.5em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tag-default {
        top: -2px;
        position: absolute;
        right: 12px;
        padding-right: 1px;
        padding-left: 5px;
        border-radius: 4px;
      }
    }
  }

  .av-name {
    font-size: 1rem;
  }
  .av-text-sm {
    font-size: 0.8125rem;
  }

  .tag-default {
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include forSize(phone) {
      margin-top: 0.625rem !important;
    }
  }
  .tag-name {
    font-size: 10px;
    line-height: 10px;
  }
}
