.more-option.dropdown {
  .btn-primary.dropdown-toggle {
    background: none;
    color: var(--title-color);
    border: none;
    border-radius: 50%;
    padding: 5px;
    box-shadow: none;
    &:hover {
      background-color: var(--cardBg);
      box-shadow: none;
    }
    &:active {
      background-color: var(--cardBg);
      border-color: #005cbf;
      box-shadow: none;
    }
    &:focus {
      background-color: var(--cardBg);;
      box-shadow: none;
    }
    &:active :focus {
      box-shadow: none;
    }
    &:after {
      display: none;
      padding: 0;
    }
    svg {
      font-size: 25px;
    }
  }
  .dropdown-menu.show {
    transform: translate3d(-117px, 40px, 0px) !important;
    border: none;
    box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
    a.dropdown-item{
      color: ver(--text-color);
    }
  }
      .dropdown-item{
      &:hover{
        background-color: var(--hoverBg);
      }
    }
}
