.alert {
  z-index: 99999;
  position: fixed;
  left: 50%;
  top: 13%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 21px 4px #0000002e;
}
.alert-danger {
  background-color: theme-color-level(danger, -10);
  color: $danger;
  border: solid 1px;
  border-color: theme-color-level(danger, -6);
}
.alert-success {
  background-color: theme-color-level(success, -10);
  color: $success;
  border: solid 1px;
  border-color: theme-color-level(success, -6);
  white-space: nowrap;
}

.alert-warning {
  background-color:theme-color-level(warning, -10);
  color: $warning;
  border: solid 1px;
  border-color: theme-color-level(warning, -6);
}
.alert.alert-info {
  background-color: theme-color-level(info, -10);
  color: $info;
  border: solid 1px;
  border-color: theme-color-level(info, -6);
}
