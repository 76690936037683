#will-info {
  color: var(--title-color);
  .will-info {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    .btn {
      margin-bottom: 0;
    }
  }
  h5 {
    color: var(--title-color);
  }
  .tags-wrapper {
    margin-bottom: 0.9375rem;
  }
}
