.floating-but {
  position: fixed;
  bottom: 30px;
  right: 42px;
  z-index: 999;
}

.floating-but button {
  background-color: $primary;
  padding: 15px;
  border-radius: 36px;
  border:  theme-color-level(primary, -10);
  box-shadow: 0 0 9px 1px #0000005e;
  color: aliceblue;
  outline: none;
}
