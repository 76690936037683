.information-box {
    background-color: #009fff17;
    border-radius: 10px;
    margin-bottom: 13px;
    padding: 17px !important;
    color: #009fff;
    span.icon {
        margin-right: 11px;
        span {
            font-size: 35px;
        }
    }
    h6 {
        color: #1890ff;
        margin-bottom: 7px;
    }
}
