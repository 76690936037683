.funeralPlansWrapper {
  .cardDefault {
    margin-bottom: 27px;
    padding-bottom: 0;
  }
  .row:last-child .cardDefault {
    margin-bottom: 0;
  }
  .remarks {
    height: 31.2rem !important;
    min-height: 141px;
    resize: none;
  }

  label.radio-inline {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
.table-custom textarea {
  height: 28px !important;
  min-height: 33px !important;
  resize: none;
  @include forSize(phone) {
    height: 58px !important;
  }
}

.hide {
  display: none;
}

.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.browse-but {
  border: solid 1px;
  border-color: var(--borderGray);
  padding: 46px !important;
  width: 100%;
  @include forSize(tablet-landscape) {
    padding: 20px !important;
  }
  @include forSize(tablet-lg) {
    padding: 39px !important;
  }
}
.tab-content {
  padding-top: 16px;
  @include forSize(phone) {
    padding-top: 14px;
  }
  button {
    @include forSize(phone) {
      margin-bottom: 10px;
    }
  }
}

/* Ishara - Commented */

// .table-custom [class*='col'] {
//   @include forSize(phone) {
//     padding: 0;
//   }
// }

.browse-but i {
  margin-bottom: 12px;
  font-size: 26px;
  color: #d1d1d1;
}

.action-hover {
  position: absolute;
  width: 100%;
  background-color: #00000059;
  padding: 0;
  top: 139px;
  transition: 0.4s;
  ul {
    width: 100%;
    padding: 0;
    text-align: center;
    margin-top: 10px;
    li {
      list-style: none;
      text-align: center;
      display: inline;
      margin: 15px;
      a {
        color: white;
        font-size: 19px;
      }
    }
  }
}
.col-lg-3.checkbox-column.invite-text.input-table {
  display: table;
  vertical-align: inherit !important;
}

.custom-control.custom-input {
  display: table-cell;
  width: 100%;
  vertical-align: inherit !important;
}

.input-table {
  display: table;
}
.video-log {
  margin-bottom: 17px;
}

.video-log-thumbnail:hover .action-hover {
  top: 0;
  transition: 0.4s;
  padding: 50px;
  @include forSize(tablet-landscape) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @include forSize(tablet-lg) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.external-link-modal {
  background-color: #f9f9f9;
  height: 100%;
  padding: 19px;
  border-left: solid 1px;
  border-color: var(--borderGray);
}
.video-log.video-log-thumbnail.select {
  border: solid 4px;
  border-color: $primary;
}
button.button.cancel {
  margin: 0;
  font-size: 14px;
  border-radius: 3px;
}

button.button.confirm {
  margin: 0;
  margin-left: 10px;
  background-color: $primary;
  border-radius: 3px;
  font-size: 13px;
}

.dataTables_info {
  @include forSize(phone) {
    text-align: right;
    margin-bottom: 12px;
  }
}
.data-table-pagination [class*='col'] {
  padding: 0;
}
.searchResults_info {
  margin-top: 10px;
}

.funeralPlansWrapper {
  // .cardTitle {
  //   margin-bottom: 15px;
  // }
}
