#my-life-story {
  .mls-dot {
    height: 34px;
    width: 34px;
    border: 8px solid #ffdec1;
    background-color: $primary;
    @include border-radius(19px);
  }

  .mls-vline {
    height: 100%;
    width: 3px;
    background-color: #ffdec1;
    flex: 2;

    // @media (min-width: 992px) {
    //   height: 50px;
    // }
  }

  .mls-right {
    margin-left: 0.75rem;
    margin-top: -5px;

    .mlsr-description {
      font-size: 1.125rem;
      font-weight: 700;
      color: var(--text-color);
    }

    .mlsr-date {
      font-size: 1.125rem;
      font-weight: 400;
      color: var(--text-color);
    }

    .mlsr-card {
      background-color: var(--cardBg1);
      padding: 1.125rem;
      border: 1px solid var(--borderGray);
      @include border-radius(5px);
      margin-top: 0.625rem;
      margin-bottom: 1.875rem;

      p {
        font-weight: 400;
        color: var(--text-color);
        margin-bottom: 0;
      }

      a {
        font-size: 0.875rem;
      }
    }
  }
}
