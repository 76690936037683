.text-limit2 {
  -webkit-line-clamp: 2;
  @include forSize(tablet-portrait) {
    -webkit-line-clamp: 2;
  }
  @include forSize(phone) {
    -webkit-line-clamp: 2;
  }
}

// this is not working. need to find a way to override common css
