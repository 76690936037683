.upload-wrp {
  background: var(--cardBg1);
  padding: 15px;
  border-radius: 4px;
  span.ant-upload.ant-upload-btn{
    background-color: var(--cardBg1);
   p{
      color:var(--text-color);
   }
  }
}
