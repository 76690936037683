.authForm {
  padding: 50px 0 35px;

  .btn {
    width: 100%;
  }

  .logoSection {
    margin-bottom: 20px;
    text-align: center;
    img {
      width: 70%;
      margin-bottom: 5px;
      @include forSize(desktop-lg) {
        width: 40%;
      }
    }
  }

  .tagLine {
    color: #7c7c7c;
    font-size: 1rem;
    font-weight: 300;
    white-space: nowrap;
  }

  .formTitle {
    margin-bottom: 15px;
    font-weight: 600;
  }

  .forgotPassword {
    margin-top: 10px;
    text-align: right;
    color: #7c7c7c;
  }

  .smallInput {
    margin-bottom: 25px;
    @include forSize(desktop-lg) {
      margin-bottom: 15px;
    }
  }

  .buttonSection {
    margin-top: 30px;
    display: flex;

    .backButton {
      background: var(--lightGray);
      flex: 0 0 40px;
      margin-right: 10px;
    }
  }

  .profilePictureSection {
    .header {
      font-family: 'Open Sans', sans-serif;
      text-align: center;
      font-size: 0.875rem;
      color: #7c7c7c;
    }

    .imageWrapper {
      width: 253px;
      height: 253px;
      margin: 30px auto 60px;
      display: flex;
      position: relative;
      @include forSize(desktop-lg) {
        width: 170px;
        height: 170px;
      }

      img {
        object-fit: cover;
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }

      .addImage {
        position: absolute;
        right: 16px;
        bottom: 0;
      }
    }

    .skipStep {
      font-size: 0.98rem;
      color: #7c7c7c;
      font-weight: 500;
      text-align: center;
      text-decoration: underline;
      margin-bottom: 40px;
    }

    .error {
      text-align: center;
      margin-bottom: 40px;
    }
  }

  .formFooterSection {
    margin-top: 20px;

    .createButton,
    .loginButton {
      margin-top: 20px;
    }
  }
}

.verifyForm {
  margin-top: 30px;

  .title {
    font-size: 1.6rem;
    font-weight: 600;
    max-width: 80%;
  }

  .description {
    margin-top: 15px;
    margin-bottom: 25px;
  }

  .resend {
    margin-top: 30px;
  }

  .formFooterSection {
    margin-top: 160px;
  }
}

.forgotPasswordForm {
  .returnToLogin {
    font-size: 0.75rem;
    margin-top: 10px;
    text-align: right;
    color: #7c7c7c;
  }
}
.lawyerRegisterForm {
  margin-top: 30px;
  font-weight: 600;
  .title {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 1.1rem;
  }

  textarea {
    resize: none;
  }
}

.register_country_selector.defult {
  .form-control {
    color: #8a8a8a;
    font-weight: 300;
    font-size: 0.9rem;
  }
}
