.will-checklist-wrapper {
  padding: 17px;
  color: #fff;
  @include border-radius(12px);
  margin-bottom: 0.875rem;

  .wc-title {
    font-size: 1.125rem;
    font-weight: 700;
  }

  .wc-sub-title {
    font-size: 1rem;
    font-weight: 500;
  }

  p {
    font-weight: 500;
    color: $white;
    margin: 0;
    margin-bottom: 2px;
    span{
      font-weight: 400;
    }
  }

  .btn {
    // padding: 13px 20px;
    margin-bottom: 1.25rem;
    width: 100%;

    @media (min-width: 992px) {
      // padding: 13px 40px;
      margin-bottom: 0;
      width: auto;
    }
  }

  .border-white {
    border: 1px solid #fff;
  }

  .wc-item {
    display: flex;
    align-items: center;
    .anticon {
      padding-right: 7px;
    }
  }
}
.will-checklist-wrapper.wcw-ornage {
  background-color: $primary;
}
.will-checklist-wrapper.wcw-green {
  background-color: $success;
}
